import NonogrammInfo from '@/types/NonogrammInfo'
import SlabPosition from '@/types/SlabPosition'
import MarkingDimensions from '@/types/MarkingDimensions'

export default class DragTracker {
	protected slabId = 0
	protected colId = 0
	protected direction = ''
	protected movement = 0
	protected marking = false
	protected abort = false
	
	protected slabSize = {
		width: 0,
		height: 0
	}
	
	protected startPos = {
		x: 0,
		y: 0
	}
	
	get isMarking (): boolean {
		return this.marking
	}
	
	get isTracking (): boolean {
		return !this.abort
	}
	
	get hasMoved (): boolean {
		return this.movement > 0
	}
	
	get numberOfMarkedSlabs (): number {
		return this.movement
	}
	
	get markingDimension (): MarkingDimensions {
		let width = this.slabSize.width
		let height = this.slabSize.height
		let top = this.startPos.y
		let left = this.startPos.x
		const hint: { top: number; left: number } = { top, left }
		
		switch (this.direction) {
		case 'top':
			height *= this.movement
			top -= height - this.slabSize.height
			hint.top = top + this.slabSize.height
			hint.left -= this.slabSize.width
			break
		case 'bottom':
			height *= this.movement
			hint.top += height - (this.slabSize.height * 2)
			hint.left -= this.slabSize.width
			break
		case 'left':
			width *= this.movement
			left -= width - this.slabSize.width
			hint.left = left
			hint.top -= this.slabSize.height
			break
		case 'right':
			width *= this.movement
			hint.left += width - (this.slabSize.width * 2)
			hint.top -= this.slabSize.height
			break
		}
		
		return {
			width: width + 'px',
			height: height + 'px',
			top: top + 'px',
			left: left + 'px',
			hint: {
				top: hint.top + 5 + 'px',
				left: hint.left + 5 + 'px'
			}
		}
	}
	
	reset (): void {
		this.slabId = 0
		this.colId = 0
		this.direction = ''
		this.movement = 0
		this.marking = false
		this.abort = false
		this.slabSize.width = 0
		this.slabSize.height = 0
		this.startPos.x = 0
		this.startPos.y = 0
	}
	
	startMarking (
		slabId: number,
		colId: number,
		slab: DOMRect
	): void {
		this.marking = true
		this.slabId = slabId
		this.colId = colId
		
		const { x, y, width, height } = slab
		
		this.startPos.x = x
		this.startPos.y = y
		this.slabSize.width = width
		this.slabSize.height = height
	}
	
	stopMarking (): void {
		this.marking = false
	}
		
	abortMarking (): void {
		if (this.marking) {
			this.abort = true
		}
	}
	
	resumeMarking (): void {
		if (this.marking) {
			this.abort = false
		}
	}

	cancelTracking (): void {
		this.abort = true
	}
	
	/**
	 * ermittelt die Anzahl der markierten Felder, sowie die Richtung und maximal markierbaren Felder der aktuellen Bewegung
	 * ist die Maximalzahl der markierbaren Felder überschritten, wird ein Falg gesetzt, sodass die Auswahl abgebrochen wird,
	 * wenn der Auswahlmodus beendet wird
	 */
	resolveMarkingMovement (
		x: number,
		y: number,
		nonogrammInfo: NonogrammInfo
	): void {
		let distanceX = (x - this.startPos.x)
		let distanceY = (y - this.startPos.y)
		
		let maxMoves
		let movement
		
		const { width, height } = this.slabSize
		
		let directionX = 'right'
		if (distanceX < 0) {
			distanceX *= -1
			distanceX += width
			directionX = 'left'
		}
		
		let directionY = 'bottom'
		if (distanceY < 0) {
			distanceY *= -1
			distanceY += height
			directionY = 'top'
		}
		
		if (distanceX > distanceY) {
			this.direction = directionX
			
			movement = Math.ceil(distanceX / width)
			maxMoves = nonogrammInfo.rows - this.slabId + 1
			if (directionX === 'left') {
				maxMoves = nonogrammInfo.rows - maxMoves + 1
			}
		} else {
			this.direction = directionY
			
			movement = Math.ceil(distanceY / height)
			maxMoves = nonogrammInfo.cols - this.colId + 1
			if (directionY === 'top') {
				maxMoves = nonogrammInfo.cols - maxMoves + 1
			}
		}
		
		if (movement <= maxMoves) {
			this.movement = movement
			this.abort = false
		} else {
			this.movement = maxMoves
			this.abort = true
		}
	}
	
	getMarkedSlabs (): SlabPosition[] {
		const markedSlabs: SlabPosition[] = []
		
		switch (this.direction) {
		case 'top':
			for (
				let i = this.colId;
				i > this.colId - this.movement;
				i--
			) {
				markedSlabs.push({
					colId: i,
					slabId: this.slabId
				})
			}
			break
		case 'bottom':
			for (
				let i = this.colId;
				i < this.colId + this.movement;
				i++
			) {
				markedSlabs.push({
					colId: i,
					slabId: this.slabId
				})
			}
			break
		case 'right':
			for (
				let i = this.slabId;
				i < this.slabId + this.movement;
				i++
			) {
				markedSlabs.push({
					colId: this.colId,
					slabId: i
				})
			}
			break
		case 'left':
			for (
				let i = this.slabId;
				i > this.slabId - this.movement;
				i--
			) {
				markedSlabs.push({
					colId: this.colId,
					slabId: i
				})
			}
			break
		default: break
		}
		
		return markedSlabs
	}
}
