
import { Options, Vue } from 'vue-class-component'

@Options({
	props: {
		blockNumbers: Array,
		small: Boolean
	}
})

export default class NumberBlock extends Vue {
}

