import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b2ce4ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "levels" }
const _hoisted_2 = { class: "scrollContainer" }
const _hoisted_3 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headline = _resolveComponent("headline")!
  const _component_level_button = _resolveComponent("level-button")!
  const _component_break_line = _resolveComponent("break-line")!
  const _component_navigation_button = _resolveComponent("navigation-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_headline, {
      title: "Levelauswahl",
      class: "wrapContent pt-3"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelInfos, (level) => {
          return (_openBlock(), _createElementBlock("div", {
            key: level.id
          }, [
            _createVNode(_component_level_button, { level: level }, null, 8, ["level"])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_break_line, { class: "wrapContent" }),
    _createVNode(_component_navigation_button, {
      class: "backButton wrapContent",
      title: "Zurück",
      navigateTo: "/"
    })
  ]))
}