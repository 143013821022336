
import { Options, Vue } from 'vue-class-component'

/* eslint-disable-next-line no-use-before-define */
@Options<Modal>({
	props: {
		isVisible: Boolean,
		noTransition: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		isVisible (newValue) {
			setTimeout(() => { this.showTransition = newValue }, 100)
		}
	}
})
export default class Modal extends Vue {
	/**
	 * triggert die Transition, wenn die Komponente sichtbar wird
	 */
	showTransition = false
}
