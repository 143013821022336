import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block", { small: _ctx.small }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockNumbers, (number) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "numbers",
        key: number.index
      }, _toDisplayString(number), 1))
    }), 128))
  ], 2))
}