
import { Options, Vue } from 'vue-class-component'
import Headline from '@/components/Headline.vue'
import NavigationButton from '@/components/NavigationButton.vue'
import LevelButton from '@/components/LevelButton.vue'
import BreakLine from '@/components/BreakLine.vue'
import NonogrammInfo from '@/types/NonogrammInfo'

import store from '@/store'

@Options({
	components: {
		BreakLine,
		LevelButton,
		NavigationButton,
		Headline
	}
})
export default class Levels extends Vue {
	/**
	 * Informationen der zur Auswahl stehenden Puzzle
	 */
	get levelInfos (): NonogrammInfo[] {
		return store.state.nonogramms.levelInfos
	}
}
