
import { Options, Vue } from 'vue-class-component'
import Headline from '@/components/Headline.vue'
import NavigationButton from '@/components/NavigationButton.vue'
import BreakLine from '@/components/BreakLine.vue'

@Options({
	components: {
		BreakLine,
		NavigationButton,
		Headline
	}
})
export default class Settings extends Vue {
	soundOn = true
	musicOn = true
	
	get soundSettingButtonTitle (): string {
		return 'Sound ' + (this.soundOn ? 'An' : 'Aus')
	}
	
	get musicSettingButtonTitle (): string {
		return 'Musik ' + (this.musicOn ? 'An' : 'Aus')
	}
}
