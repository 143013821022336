<template>
	<div class="breakLine">
		<svg xmlns="http://www.w3.org/2000/svg" width="331" height="28.069" viewBox="0 0 331 28.069">
			<g id="Gruppe_24" data-name="Gruppe 24" transform="translate(-40.5 -547.435)">
				<line id="Linie_6" data-name="Linie 6" x2="132" transform="translate(41 561)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
				<line id="Linie_9" data-name="Linie 9" x2="132" transform="translate(239 561)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
				<g id="Pfad_13" data-name="Pfad 13" transform="translate(192.203 561.707) rotate(-45)" fill="none" stroke-linecap="round" stroke-linejoin="round">
					<path d="M4.273,4.165l15.575-4.5-4.5,15.58L0,19.512Z" stroke="none"/>
					<path d="M 18.38484954833984 1.127933502197266 L 5.086410522460938 4.971076011657715 L 1.438329696655273 18.07444953918457 L 14.54572868347168 14.43038845062256 L 18.38484954833984 1.127933502197266 M 19.84812164306641 -0.3358650207519531 L 15.35166168212891 15.24425601959229 L 1.9073486328125e-06 19.51225662231445 L 4.272711753845215 4.165306091308594 L 19.84812164306641 -0.3358650207519531 Z" stroke="none" fill="#fff"/>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'

@Options({})

export default class BreakLine extends Vue {
}
</script>

<style scoped>
.breakLine {
	margin: 2rem 0
}

.breakLine > svg {
	max-width: 100%;
	height: auto;
}
</style>
