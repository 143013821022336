import DragTracker from '@/models/DragTracker'

import Tracker from '@/utils/CreataleTracker'

export default class TrackingDragTracker extends DragTracker {
	trackMovement (): void {
		Tracker.addEvent({
			type: 'MARK_SLABS',
			value: JSON.stringify({
				colId: this.colId,
				slabId: this.slabId,
				direction: this.direction,
				count: this.movement
			}),
			doubleValue: this.movement
		})
	}
}
