import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ac8d271"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imageContainer" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headline = _resolveComponent("headline")!
  const _component_navigation_button = _resolveComponent("navigation-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "image",
        src: _ctx.imageSrc,
        alt: _ctx.title
      }, null, 8, _hoisted_2)
    ]),
    _createVNode(_component_headline, { title: _ctx.title }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.lastLevel)
        ? (_openBlock(), _createBlock(_component_navigation_button, {
            key: 0,
            icon: "fa-solid fa-chevron-right",
            title: "Weiter",
            navigateTo: `/play/${_ctx.levelId + 1}`
          }, null, 8, ["navigateTo"]))
        : _createCommentVNode("", true),
      _createVNode(_component_navigation_button, {
        icon: "fa-solid fa-bars",
        title: "Menü",
        navigateTo: "/"
      }),
      _createVNode(_component_navigation_button, {
        icon: "fa-solid fa-arrow-rotate-left",
        title: "Restart",
        onClick: _ctx.resetPuzzle
      }, null, 8, ["onClick"])
    ])
  ]))
}