import KeyDefinition from '@/types/KeyDefinition'
import LocalStorageOptions from '@/types/LocalStorageOptions'

class LocalStorageProvider {
	initialized: boolean
	localStorageAvailable: boolean
	keyDefinitions: Record<string, KeyDefinition>
	// eslint-disable-next-line no-undef
	[key: string]: unknown

	constructor () {
		this.initialized = false
		this.localStorageAvailable = false
		this.keyDefinitions = {}
	}

	initialize (options: LocalStorageOptions): void {
		const { localStoragePrefix, keyDefinitions } = options

		for (const key of Object.keys(keyDefinitions)) {
			this.keyDefinitions[key] = {
				...keyDefinitions[key],
				localStoragePrefix
			}
		}

		const testKey = localStoragePrefix + 'test'
		try {
			localStorage.setItem(testKey, testKey)
			localStorage.removeItem(testKey)
			this.localStorageAvailable = true
		} catch {
			this.localStorageAvailable = false
		}

		this.restore()

		this.initialized = true
	}

	restore (): void {
		for (const key of Object.keys(this.keyDefinitions)) {
			this.loadKey(key)
		}
	}

	loadKey (key: string): void {
		if (this.keyDefinitions[key]) {
			const { localStoragePrefix, deserialize, defaultValue } = this.keyDefinitions[key]
			const rawValue = this.localStorageAvailable ? localStorage.getItem(localStoragePrefix + key) : null
			let deserializedValue
			try {
				deserializedValue = rawValue ? (deserialize ? deserialize(rawValue) : rawValue) : defaultValue
			} catch {
			}

			this[key] = deserializedValue !== undefined ? deserializedValue : defaultValue
			this.saveKey(key)
		}
	}

	saveKey (key: string): void {
		if (this.initialized && this.localStorageAvailable && this.keyDefinitions[key]) {
			const { localStoragePrefix, serialize } = this.keyDefinitions[key]
			localStorage.setItem(localStoragePrefix + key, (serialize ? serialize(this[key]) : this[key]) as string)
		}
	}

	save (data: Record<string, unknown>): void {
		if (this.initialized) {
			for (const key of Object.keys(data)) {
				this[key] = data[key]
				this.saveKey(key)
			}
		}
	}
}

const localStorageProviderInstance = new LocalStorageProvider()

export default localStorageProviderInstance
