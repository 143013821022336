import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.navigateTo ? 'router-link' : 'div'), {
    class: _normalizeClass({ button: true, disabled: _ctx.disabled }),
    to: _ctx.navigateTo
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            size: _ctx.iconSize,
            icon: _ctx.icon
          }, null, 8, ["size", "icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: "text",
        style: _normalizeStyle({ fontSize: _ctx.textSize, lineHeight: _ctx.textLineHeight })
      }, _toDisplayString(_ctx.title), 5)
    ]),
    _: 1
  }, 8, ["class", "to"]))
}