
import { Options, Vue } from 'vue-class-component'

@Options({
	props: {
		icon: String,
		iconSize: {
			type: String,
			default: '2x'
		},
		title: String,
		textSize: {
			type: String,
			default: '24px'
		},
		textLineHeight: {
			type: String
		},
		disabled: {
			type: Boolean,
			default: false
		},
		navigateTo: {
			type: String
		}
	}
})

export default class NavigationButton extends Vue {
}
