
import { Options, Vue } from 'vue-class-component'

import Headline from '@/components/Headline.vue'
import NavigationButton from '@/components/NavigationButton.vue'

import Tracker from '@/utils/CreataleTracker'

import config from '@/config'

@Options({
	components: {
		Headline,
		NavigationButton
	}
})
export default class MainMenu extends Vue {
	/**
	 * Zeigt an, ob die WebShare API auf dem Gerät verfügbar ist.
	 */
	sharingEnabled = !!(navigator.share)

	/**
	 * Prüft, ob die WebShare API verfügbar ist und ermöglicht dann das Teilen der Anwendung.
	 *
	 * Wenn der Nutzer Tracking aktiviert hat, dann wird der Versuch und der Erfolg vom Sharing getrackt.
	 */
	onShare (): void {
		if (navigator.share) {
			Tracker.addEvent({
				type: 'UI_INTERACTION',
				subType: 'TRY_SHARE'
			})
			
			navigator.share({
				title: 'Christmas PiX',
				text: 'Kannst du alle Nonogramme lösen?',
				url: config.shareUrl
			})
				.then(() => {
					Tracker.addEvent({
						type: 'UI_INTERACTION',
						subType: 'SHARE_SUCCESS'
					})
					console.log('Successful share')
					
					return Promise.resolve()
				})
				.catch((error) => console.log('Error sharing', error))
		}
	}
}
