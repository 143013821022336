
import { Vue } from 'vue-class-component'
import config from '@/config'
import LocalStorageProvider from '@/utils/LocalStorageProvider'

export default class App extends Vue {
	mounted (): void {
		LocalStorageProvider.initialize(config.localStorage)
	}
}
