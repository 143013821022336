import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_button = _resolveComponent("navigation-button")!
  const _component_HowToPlay = _resolveComponent("HowToPlay", true)!

  return (_openBlock(), _createBlock(_component_HowToPlay, null, {
    default: _withCtx(() => [
      (_ctx.firstTimeTutorial)
        ? (_openBlock(), _createBlock(_component_navigation_button, {
            key: 0,
            icon: "chevron-right",
            title: "Spielen",
            onClick: _ctx.play
          }, null, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_navigation_button, {
            key: 1,
            icon: "chevron-left",
            title: "Zurück",
            navigateTo: "/"
          }))
    ]),
    _: 1
  }))
}