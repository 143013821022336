import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c06b6176"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imageContainer" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      class: "button",
      to: _ctx.navigationPath
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "previewImage",
            src: _ctx.imagePath
          }, null, 8, _hoisted_2)
        ])
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
  ], 64))
}