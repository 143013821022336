enum SlabStateEnum {
	/**
	 * das Feld ist korrekt ausgefüllt
	 */
	coloredSlab = 'coloredSlab',

	/**
	 * das Feld wurde durch den Spieler blockiert
	 */
	markedByPlayer = 'markedByPlayer',

	/**
	 * das Spiel wurde falsch ausgefüllt
	 */
	crossedOut = 'crossedOut',

	/**
	 * das Feld ist nicht ausgefüllt
	 */
	emptySlab = 'emptySlab'
}

export default SlabStateEnum
