
import { Options, Vue } from 'vue-class-component'
import Headline from '@/components/Headline.vue'
import NavigationButton from '@/components/NavigationButton.vue'

@Options({
	components: {
		NavigationButton,
		Headline
	},
	props: {
		title: String,
		levelId: Number,
		lastLevel: Boolean,
		imageSrc: String
	}
})
export default class PuzzleSolved extends Vue {
	/**
	 * Gibt einen Event aus, dass das aktuelle Puzzle zurückgesetzt werden soll
	 */
	resetPuzzle (): void {
		this.$emit('resetPuzzle')
	}
}
