import { vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a422b7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dragMarkingContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "dragMarking",
      style: _normalizeStyle(_ctx.markingDimension)
    }, null, 4), [
      [_vShow, _ctx.dragTracker.isMarking && _ctx.dragTracker.hasMoved]
    ]),
    (_ctx.dragTracker.numberOfMarkedSlabs > 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dragHint",
          style: _normalizeStyle(_ctx.dragHintStyle)
        }, _toDisplayString(_ctx.dragTracker.isTracking ? _ctx.dragTracker.numberOfMarkedSlabs : 'X'), 5))
      : _createCommentVNode("", true)
  ]))
}