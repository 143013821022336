import Nonogramm from '@/models/Nonogramm'
import NonogrammInfo from '@/types/NonogrammInfo'

import getPuzzleInfo from '@/utils/getPuzzleInfo'

const PUZZLES: Nonogramm[] = [{
	id: 1,
	name: 'penguin',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#5f6978'
				},
				{
					id: 4,
					color: '#40445a'
				},
				{
					id: 5,
					color: '#40445a'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#171429'
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#5f6978'
				},
				{
					id: 3,
					color: '#40445a'
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#171429'
				},
				{
					id: 8,
					color: '#171429'
				},
				{
					id: 9,
					color: '#171429'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#5f6978'
				},
				{
					id: 3,
					color: '#222034'
				},
				{
					id: 4,
					color: '#cbdbfc'
				},
				{
					id: 5,
					color: '#cbdbfc'
				},
				{
					id: 6,
					color: '#cbdbfc'
				},
				{
					id: 7,
					color: '#94ace9'
				},
				{
					id: 8,
					color: '#171429'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#40445a'
				},
				{
					id: 3,
					color: '#cbdbfc'
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#cbdbfc'
				},
				{
					id: 6,
					color: '#94ace9'
				},
				{
					id: 7
				},
				{
					id: 8,
					color: '#747bda'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#40445a'
				},
				{
					id: 3,
					color: '#cbdbfc'
				},
				{
					id: 4,
					color: '#cbdbfc'
				},
				{
					id: 5,
					color: '#e99134'
				},
				{
					id: 6,
					color: '#d63e16'
				},
				{
					id: 7,
					color: '#747bda'
				},
				{
					id: 8,
					color: '#747bda'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1,
					color: '#40445a'
				},
				{
					id: 2,
					color: '#222034'
				},
				{
					id: 3,
					color: '#cbdbfc'
				},
				{
					id: 4,
					color: '#cbdbfc'
				},
				{
					id: 5,
					color: '#df7126'
				},
				{
					id: 6,
					color: '#d63e16'
				},
				{
					id: 7,
					color: '#94ace9'
				},
				{
					id: 8,
					color: '#747bda'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10,
					color: '#171429'
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1,
					color: '#171429'
				},
				{
					id: 2,
					color: '#222034'
				},
				{
					id: 3,
					color: '#cbdbfc'
				},
				{
					id: 4,
					color: '#cbdbfc'
				},
				{
					id: 5,
					color: '#94ace9'
				},
				{
					id: 6,
					color: '#94ace9'
				},
				{
					id: 7,
					color: '#747bda'
				},
				{
					id: 8,
					color: '#747bda'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10,
					color: '#100b1e'
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1,
					color: '#100b1e'
				},
				{
					id: 2,
					color: '#171429'
				},
				{
					id: 3,
					color: '#cbdbfc'
				},
				{
					id: 4,
					color: '#94ace9'
				},
				{
					id: 5,
					color: '#94ace9'
				},
				{
					id: 6,
					color: '#747bda'
				},
				{
					id: 7,
					color: '#94ace9'
				},
				{
					id: 8,
					color: '#747bda'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10,
					color: '#100b1e'
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#100b1e'
				},
				{
					id: 3,
					color: '#94ace9'
				},
				{
					id: 4,
					color: '#94ace9'
				},
				{
					id: 5,
					color: '#747bda'
				},
				{
					id: 6,
					color: '#94ace9'
				},
				{
					id: 7,
					color: '#747bda'
				},
				{
					id: 8,
					color: '#747bda'
				},
				{
					id: 9,
					color: '#100b1e'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#e99134'
				},
				{
					id: 4,
					color: '#d63e16'
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#df7126'
				},
				{
					id: 8,
					color: '#d63e16'
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		}
	]
}, {
	id: 2,
	name: 'baubel',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#a51a36'
				},
				{
					id: 5,
					color: '#bf314f'
				},
				{
					id: 6,
					color: '#a51a36'
				},
				{
					id: 7,
					color: '#a51a36'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#bf314f'
				},
				{
					id: 4,
					color: '#f08d8d'
				},
				{
					id: 5,
					color: '#d65865'
				},
				{
					id: 6,
					color: '#bf314f'
				},
				{
					id: 7,
					color: '#a51a36'
				},
				{
					id: 8,
					color: '#a51a36'
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#a51a36'
				},
				{
					id: 3,
					color: '#f0aeae'
				},
				{
					id: 4,
					color: '#d65865'
				},
				{
					id: 5,
					color: '#bf314f'
				},
				{
					id: 6,
					color: '#a51a36'
				},
				{
					id: 7,
					color: '#a51a36'
				},
				{
					id: 8,
					color: '#a51a36'
				},
				{
					id: 9,
					color: '#a51a36'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1,
					color: '#a51a36'
				},
				{
					id: 2,
					color: '#bf314f'
				},
				{
					id: 3,
					color: '#d65865'
				},
				{
					id: 4,
					color: '#bf314f'
				},
				{
					id: 5,
					color: '#a51a36'
				},
				{
					id: 6,
					color: '#a51a36'
				},
				{
					id: 7,
					color: '#a51a36'
				},
				{
					id: 8,
					color: '#a51a36'
				},
				{
					id: 9,
					color: '#800243'
				},
				{
					id: 10,
					color: '#a51a36'
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1,
					color: '#a51a36'
				},
				{
					id: 2,
					color: '#a51a36'
				},
				{
					id: 3,
					color: '#bf314f'
				},
				{
					id: 4,
					color: '#a51a36'
				},
				{
					id: 5,
					color: '#a51a36'
				},
				{
					id: 6,
					color: '#a51a36'
				},
				{
					id: 7,
					color: '#a51a36'
				},
				{
					id: 8,
					color: '#800243'
				},
				{
					id: 9,
					color: '#a51a36'
				},
				{
					id: 10,
					color: '#800243'
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1,
					color: '#a51a36'
				},
				{
					id: 2,
					color: '#a51a36'
				},
				{
					id: 3,
					color: '#a51a36'
				},
				{
					id: 4,
					color: '#a51a36'
				},
				{
					id: 5,
					color: '#a51a36'
				},
				{
					id: 6,
					color: '#a51a36'
				},
				{
					id: 7,
					color: '#800243'
				},
				{
					id: 8,
					color: '#a51a36'
				},
				{
					id: 9,
					color: '#800243'
				},
				{
					id: 10,
					color: '#800243'
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1,
					color: '#800243'
				},
				{
					id: 2,
					color: '#a51a36'
				},
				{
					id: 3,
					color: '#a51a36'
				},
				{
					id: 4,
					color: '#a51a36'
				},
				{
					id: 5,
					color: '#800243'
				},
				{
					id: 6,
					color: '#800243'
				},
				{
					id: 7,
					color: '#a51a36'
				},
				{
					id: 8,
					color: '#800243'
				},
				{
					id: 9,
					color: '#bf314f'
				},
				{
					id: 10,
					color: '#800243'
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#800243'
				},
				{
					id: 3,
					color: '#800243'
				},
				{
					id: 4,
					color: '#800243'
				},
				{
					id: 5,
					color: '#800243'
				},
				{
					id: 6,
					color: '#800243'
				},
				{
					id: 7,
					color: '#800243'
				},
				{
					id: 8,
					color: '#d65865'
				},
				{
					id: 9,
					color: '#800243'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#800243'
				},
				{
					id: 4,
					color: '#800243'
				},
				{
					id: 5,
					color: '#800243'
				},
				{
					id: 6,
					color: '#800243'
				},
				{
					id: 7,
					color: '#bf314f'
				},
				{
					id: 8,
					color: '#800243'
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#800243'
				},
				{
					id: 5,
					color: '#800243'
				},
				{
					id: 6,
					color: '#800243'
				},
				{
					id: 7,
					color: '#800243'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		}
	]
}, {
	id: 3,
	name: 'present',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#fbf236'
				},
				{
					id: 3,
					color: '#fbf236'
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#fbf236'
				},
				{
					id: 8,
					color: '#fbf236'
				},
				{
					id: 9,
					color: '#fbf236'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1,
					color: '#fbf236'
				},
				{
					id: 2,
					color: '#e5c943'
				},
				{
					id: 3,
					color: '#d95763'
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5,
					color: '#fbf236'
				},
				{
					id: 6,
					color: '#fbf236'
				},
				{
					id: 7,
					color: '#e5c943'
				},
				{
					id: 8,
					color: '#d95763'
				},
				{
					id: 9,
					color: '#fbf236'
				},
				{
					id: 10,
					color: '#fbf236'
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1,
					color: '#fbf236'
				},
				{
					id: 2,
					color: '#fbf236'
				},
				{
					id: 3,
					color: '#e5c943'
				},
				{
					id: 4,
					color: '#bca120'
				},
				{
					id: 5,
					color: '#fbf236'
				},
				{
					id: 6,
					color: '#e5c943'
				},
				{
					id: 7,
					color: '#ad810f'
				},
				{
					id: 8,
					color: '#fbf236'
				},
				{
					id: 9,
					color: '#e5c943'
				},
				{
					id: 10,
					color: '#fbf236'
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#fbf236'
				},
				{
					id: 3,
					color: '#fbf236'
				},
				{
					id: 4,
					color: '#e5c943'
				},
				{
					id: 5,
					color: '#e5c943'
				},
				{
					id: 6,
					color: '#fbf236'
				},
				{
					id: 7,
					color: '#fbf236'
				},
				{
					id: 8,
					color: '#e5c943'
				},
				{
					id: 9,
					color: '#e5c943'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#d95763'
				},
				{
					id: 3,
					color: '#fbf236'
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5,
					color: '#e5c943'
				},
				{
					id: 6,
					color: '#e5c943'
				},
				{
					id: 7,
					color: '#e5c943'
				},
				{
					id: 8,
					color: '#e5c943'
				},
				{
					id: 9,
					color: '#bf3854'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#fbf236'
				},
				{
					id: 3,
					color: '#fbf236'
				},
				{
					id: 4,
					color: '#e5c943'
				},
				{
					id: 5,
					color: '#bca120'
				},
				{
					id: 6,
					color: '#bca120'
				},
				{
					id: 7,
					color: '#fbf236'
				},
				{
					id: 8,
					color: '#e5c943'
				},
				{
					id: 9,
					color: '#e5c943'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1,
					color: '#fbf236'
				},
				{
					id: 2,
					color: '#fbf236'
				},
				{
					id: 3,
					color: '#e5c943'
				},
				{
					id: 4,
					color: '#9e0836'
				},
				{
					id: 5,
					color: '#ad810f'
				},
				{
					id: 6,
					color: '#ad810f'
				},
				{
					id: 7,
					color: '#9e0836'
				},
				{
					id: 8,
					color: '#fbf236'
				},
				{
					id: 9,
					color: '#e5c943'
				},
				{
					id: 10,
					color: '#e5c943'
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#9e0836'
				},
				{
					id: 3,
					color: '#9e0836'
				},
				{
					id: 4,
					color: '#9e0836'
				},
				{
					id: 5,
					color: '#ad810f'
				},
				{
					id: 6,
					color: '#ad810f'
				},
				{
					id: 7,
					color: '#9e0836'
				},
				{
					id: 8,
					color: '#9e0836'
				},
				{
					id: 9,
					color: '#9e0836'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#45283c'
				},
				{
					id: 3,
					color: '#9e0836'
				},
				{
					id: 4,
					color: '#9e0836'
				},
				{
					id: 5,
					color: '#ad810f'
				},
				{
					id: 6,
					color: '#ad810f'
				},
				{
					id: 7,
					color: '#9e0836'
				},
				{
					id: 8,
					color: '#9e0836'
				},
				{
					id: 9,
					color: '#45283c'
				},
				{
					id: 10
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#45283c'
				},
				{
					id: 4,
					color: '#45283c'
				},
				{
					id: 5,
					color: '#45283c'
				},
				{
					id: 6,
					color: '#45283c'
				},
				{
					id: 7,
					color: '#45283c'
				},
				{
					id: 8,
					color: '#45283c'
				},
				{
					id: 9
				},
				{
					id: 10
				}
			]
		}
	]
}, {
	id: 4,
	name: 'candles',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#bf3728'
				},
				{
					id: 3,
					color: '#830909'
				},
				{
					id: 4,
					color: '#fbf6ad'
				},
				{
					id: 5,
					color: '#830909'
				},
				{
					id: 6,
					color: '#830909'
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#830909'
				},
				{
					id: 3,
					color: '#830909'
				},
				{
					id: 4,
					color: '#df7126'
				},
				{
					id: 5,
					color: '#830909'
				},
				{
					id: 6,
					color: '#830909'
				},
				{
					id: 7,
					color: '#830909'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12,
					color: '#fbf236'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#bf3728'
				},
				{
					id: 3,
					color: '#bf3728'
				},
				{
					id: 4,
					color: '#bf3728'
				},
				{
					id: 5,
					color: '#9e1313'
				},
				{
					id: 6,
					color: '#9e1313'
				},
				{
					id: 7,
					color: '#830909'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12,
					color: '#fbf6ad'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#9e1313'
				},
				{
					id: 3,
					color: '#9e1313'
				},
				{
					id: 4,
					color: '#830909'
				},
				{
					id: 5,
					color: '#9e1313'
				},
				{
					id: 6,
					color: '#830909'
				},
				{
					id: 7,
					color: '#830909'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11,
					color: '#b02b70'
				},
				{
					id: 12,
					color: '#df7126'
				},
				{
					id: 13,
					color: '#800777'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#9e1313'
				},
				{
					id: 3,
					color: '#830909'
				},
				{
					id: 4,
					color: '#9e1313'
				},
				{
					id: 5,
					color: '#830909'
				},
				{
					id: 6,
					color: '#650303'
				},
				{
					id: 7,
					color: '#650303'
				},
				{
					id: 8,
					color: '#fbf236'
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#800777'
				},
				{
					id: 11,
					color: '#800777'
				},
				{
					id: 12,
					color: '#b02b70'
				},
				{
					id: 13,
					color: '#b02b70'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#bf3728'
				},
				{
					id: 3,
					color: '#9e1313'
				},
				{
					id: 4,
					color: '#830909'
				},
				{
					id: 5,
					color: '#650303'
				},
				{
					id: 6,
					color: '#9369a9'
				},
				{
					id: 7,
					color: '#45256d'
				},
				{
					id: 8,
					color: '#fbf6ad'
				},
				{
					id: 9,
					color: '#45256d'
				},
				{
					id: 10,
					color: '#311a5e'
				},
				{
					id: 11,
					color: '#5b0069'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#800777'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#9e1313'
				},
				{
					id: 3,
					color: '#830909'
				},
				{
					id: 4,
					color: '#650303'
				},
				{
					id: 5,
					color: '#9369a9'
				},
				{
					id: 6,
					color: '#45256d'
				},
				{
					id: 7,
					color: '#45256d'
				},
				{
					id: 8,
					color: '#df7126'
				},
				{
					id: 9,
					color: '#45256d'
				},
				{
					id: 10,
					color: '#45256d'
				},
				{
					id: 11,
					color: '#311a5e'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#9a1065'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#830909'
				},
				{
					id: 3,
					color: '#9e1313'
				},
				{
					id: 4,
					color: '#650303'
				},
				{
					id: 5,
					color: '#9369a9'
				},
				{
					id: 6,
					color: '#9369a9'
				},
				{
					id: 7,
					color: '#9369a9'
				},
				{
					id: 8,
					color: '#9369a9'
				},
				{
					id: 9,
					color: '#76428a'
				},
				{
					id: 10,
					color: '#76428a'
				},
				{
					id: 11,
					color: '#311a5e'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#800777'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1,
					color: '#bf3728'
				},
				{
					id: 2,
					color: '#9e1313'
				},
				{
					id: 3,
					color: '#830909'
				},
				{
					id: 4,
					color: '#650303'
				},
				{
					id: 5,
					color: '#9369a9'
				},
				{
					id: 6,
					color: '#76428a'
				},
				{
					id: 7,
					color: '#76428a'
				},
				{
					id: 8,
					color: '#45256d'
				},
				{
					id: 9,
					color: '#76428a'
				},
				{
					id: 10,
					color: '#45256d'
				},
				{
					id: 11,
					color: '#311a5e'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#5b0069'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 11,
			rows: [
				{
					id: 1,
					color: '#9e1313'
				},
				{
					id: 2,
					color: '#830909'
				},
				{
					id: 3,
					color: '#830909'
				},
				{
					id: 4,
					color: '#650303'
				},
				{
					id: 5,
					color: '#9369a9'
				},
				{
					id: 6,
					color: '#9369a9'
				},
				{
					id: 7,
					color: '#76428a'
				},
				{
					id: 8,
					color: '#76428a'
				},
				{
					id: 9,
					color: '#45256d'
				},
				{
					id: 10,
					color: '#76428a'
				},
				{
					id: 11,
					color: '#311a5e'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#5b0069'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15,
					color: '#1c0a22'
				}
			]
		},
		{
			id: 12,
			rows: [
				{
					id: 1,
					color: '#1c0a22'
				},
				{
					id: 2,
					color: '#830909'
				},
				{
					id: 3,
					color: '#650303'
				},
				{
					id: 4,
					color: '#650303'
				},
				{
					id: 5,
					color: '#9369a9'
				},
				{
					id: 6,
					color: '#76428a'
				},
				{
					id: 7,
					color: '#76428a'
				},
				{
					id: 8,
					color: '#45256d'
				},
				{
					id: 9,
					color: '#76428a'
				},
				{
					id: 10,
					color: '#45256d'
				},
				{
					id: 11,
					color: '#311a5e'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#5b0069'
				},
				{
					id: 14,
					color: '#5b0069'
				},
				{
					id: 15,
					color: '#1c0a22'
				}
			]
		},
		{
			id: 13,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#1c0a22'
				},
				{
					id: 3,
					color: '#1c0a22'
				},
				{
					id: 4,
					color: '#1c0a22'
				},
				{
					id: 5,
					color: '#76428a'
				},
				{
					id: 6,
					color: '#76428a'
				},
				{
					id: 7,
					color: '#76428a'
				},
				{
					id: 8,
					color: '#76428a'
				},
				{
					id: 9,
					color: '#45256d'
				},
				{
					id: 10,
					color: '#45256d'
				},
				{
					id: 11,
					color: '#311a5e'
				},
				{
					id: 12,
					color: '#5b0069'
				},
				{
					id: 13,
					color: '#5b0069'
				},
				{
					id: 14,
					color: '#1c0a22'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 14,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#1c0a22'
				},
				{
					id: 6,
					color: '#76428a'
				},
				{
					id: 7,
					color: '#76428a'
				},
				{
					id: 8,
					color: '#45256d'
				},
				{
					id: 9,
					color: '#45256d'
				},
				{
					id: 10,
					color: '#311a5e'
				},
				{
					id: 11,
					color: '#1c0a22'
				},
				{
					id: 12,
					color: '#1c0a22'
				},
				{
					id: 13,
					color: '#1c0a22'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 15,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#1c0a22'
				},
				{
					id: 7,
					color: '#1c0a22'
				},
				{
					id: 8,
					color: '#1c0a22'
				},
				{
					id: 9,
					color: '#1c0a22'
				},
				{
					id: 10,
					color: '#1c0a22'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		}
	]
}, {
	id: 5,
	name: 'cat',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#3a3e4f'
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7
				},
				{
					id: 8,
					color: '#3a3e4f'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#3a3e4f'
				},
				{
					id: 14,
					color: '#000000'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#3a3e4f'
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#3a3e4f'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#3a3e4f'
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#3a3e4f'
				},
				{
					id: 6,
					color: '#3a3e4f'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#3a3e4f'
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#fbf236'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#3a3e4f'
				},
				{
					id: 3,
					color: '#222034'
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#d95763'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#3a3e4f'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#222034'
				},
				{
					id: 3,
					color: '#222034'
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#000000'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#222034'
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#3a3e4f'
				},
				{
					id: 12,
					color: '#3a3e4f'
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#222034'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#000000'
				},
				{
					id: 8,
					color: '#000000'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#222034'
				},
				{
					id: 12,
					color: '#222034'
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#cbdbfc'
				},
				{
					id: 5,
					color: '#cbdbfc'
				},
				{
					id: 6,
					color: '#cbdbfc'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#222034'
				},
				{
					id: 12,
					color: '#222034'
				},
				{
					id: 13,
					color: '#222034'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#3a3e4f'
				},
				{
					id: 5,
					color: '#cbdbfc'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#222034'
				},
				{
					id: 12,
					color: '#222034'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 11,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#3a3e4f'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#222034'
				},
				{
					id: 12,
					color: '#222034'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 12,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#3a3e4f'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#222034'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#222034'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#222034'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 13,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#3a3e4f'
				},
				{
					id: 5,
					color: '#222034'
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8,
					color: '#222034'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 14,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#3a3e4f'
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#222034'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#222034'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 15,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#3a3e4f'
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#3a3e4f'
				},
				{
					id: 7,
					color: '#000000'
				},
				{
					id: 8
				},
				{
					id: 9,
					color: '#3a3e4f'
				},
				{
					id: 10,
					color: '#000000'
				},
				{
					id: 11
				},
				{
					id: 12,
					color: '#3a3e4f'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		}
	]
}, {
	id: 6,
	name: 'elf',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10,
					color: '#000000'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7
				},
				{
					id: 8,
					color: '#000000'
				},
				{
					id: 9,
					color: '#4b692f'
				},
				{
					id: 10,
					color: '#920a26'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#000000'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#195616'
				},
				{
					id: 10,
					color: '#920a26'
				},
				{
					id: 11,
					color: '#920a26'
				},
				{
					id: 12,
					color: '#fbf236'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#ac3232'
				},
				{
					id: 8,
					color: '#ac3232'
				},
				{
					id: 9,
					color: '#920a26'
				},
				{
					id: 10,
					color: '#195616'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12,
					color: '#abc318'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#000000'
				},
				{
					id: 3,
					color: '#000000'
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#4b692f'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#195616'
				},
				{
					id: 9,
					color: '#195616'
				},
				{
					id: 10,
					color: '#195616'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14,
					color: '#000000'
				},
				{
					id: 15,
					color: '#000000'
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#000000'
				},
				{
					id: 3,
					color: '#4a53d6'
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#4b692f'
				},
				{
					id: 6,
					color: '#3732c3'
				},
				{
					id: 7,
					color: '#3732c3'
				},
				{
					id: 8,
					color: '#3732c3'
				},
				{
					id: 9,
					color: '#3732c3'
				},
				{
					id: 10,
					color: '#195616'
				},
				{
					id: 11,
					color: '#195616'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14,
					color: '#5b6ee1'
				},
				{
					id: 15,
					color: '#000000'
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#000000'
				},
				{
					id: 4,
					color: '#4a53d6'
				},
				{
					id: 5,
					color: '#5b6ee1'
				},
				{
					id: 6,
					color: '#cbdbfc'
				},
				{
					id: 7,
					color: '#5b6ee1'
				},
				{
					id: 8,
					color: '#5b6ee1'
				},
				{
					id: 9,
					color: '#cbdbfc'
				},
				{
					id: 10,
					color: '#4a53d6'
				},
				{
					id: 11,
					color: '#3732c3'
				},
				{
					id: 12,
					color: '#5b6ee1'
				},
				{
					id: 13,
					color: '#5b6ee1'
				},
				{
					id: 14,
					color: '#000000'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#000000'
				},
				{
					id: 4,
					color: '#3732c3'
				},
				{
					id: 5,
					color: '#5b6ee1'
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#4a53d6'
				},
				{
					id: 8,
					color: '#4a53d6'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10,
					color: '#4a53d6'
				},
				{
					id: 11,
					color: '#4a53d6'
				},
				{
					id: 12,
					color: '#3732c3'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#5b6ee1'
				},
				{
					id: 6,
					color: '#5b6ee1'
				},
				{
					id: 7,
					color: '#5b6ee1'
				},
				{
					id: 8,
					color: '#5b6ee1'
				},
				{
					id: 9,
					color: '#5b6ee1'
				},
				{
					id: 10,
					color: '#3732c3'
				},
				{
					id: 11,
					color: '#3732c3'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#5b6ee1'
				},
				{
					id: 7,
					color: '#5b6ee1'
				},
				{
					id: 8,
					color: '#5b6ee1'
				},
				{
					id: 9,
					color: '#3732c3'
				},
				{
					id: 10,
					color: '#3732c3'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 11,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#000000'
				},
				{
					id: 8,
					color: '#4a53d6'
				},
				{
					id: 9,
					color: '#3732c3'
				},
				{
					id: 10,
					color: '#000000'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 12,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#195616'
				},
				{
					id: 10,
					color: '#195616'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 13,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#fbf236'
				},
				{
					id: 7,
					color: '#ac3232'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#195616'
				},
				{
					id: 10,
					color: '#abc318'
				},
				{
					id: 11,
					color: '#920a26'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 14,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#ac3232'
				},
				{
					id: 7,
					color: '#ac3232'
				},
				{
					id: 8,
					color: '#fbf236'
				},
				{
					id: 9,
					color: '#920a26'
				},
				{
					id: 10,
					color: '#920a26'
				},
				{
					id: 11,
					color: '#920a26'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 15,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#ac3232'
				},
				{
					id: 7,
					color: '#ac3232'
				},
				{
					id: 8,
					color: '#ac3232'
				},
				{
					id: 9,
					color: '#ac3232'
				},
				{
					id: 10,
					color: '#920a26'
				},
				{
					id: 11,
					color: '#920a26'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		}
	]
}, {
	id: 7,
	name: 'tree',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7
				},
				{
					id: 8,
					color: '#02130c'
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#02130c'
				},
				{
					id: 8,
					color: '#7a874f'
				},
				{
					id: 9,
					color: '#02130c'
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#02130c'
				},
				{
					id: 7,
					color: '#7a874f'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#bfa00a'
				},
				{
					id: 10,
					color: '#02130c'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#02130c'
				},
				{
					id: 6,
					color: '#7a874f'
				},
				{
					id: 7,
					color: '#ac3232'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#1d5a22'
				},
				{
					id: 10,
					color: '#0d4020'
				},
				{
					id: 11,
					color: '#02130c'
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#02130c'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#1d5a22'
				},
				{
					id: 9,
					color: '#0d4020'
				},
				{
					id: 10,
					color: '#02130c'
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#02130c'
				},
				{
					id: 6,
					color: '#7a874f'
				},
				{
					id: 7,
					color: '#fbf236'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#871515'
				},
				{
					id: 10,
					color: '#1d5a22'
				},
				{
					id: 11,
					color: '#02130c'
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#02130c'
				},
				{
					id: 5,
					color: '#7a874f'
				},
				{
					id: 6,
					color: '#4b692f'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#5fcde4'
				},
				{
					id: 9,
					color: '#4b692f'
				},
				{
					id: 10,
					color: '#1d5a22'
				},
				{
					id: 11,
					color: '#0d4020'
				},
				{
					id: 12,
					color: '#02130c'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#02130c'
				},
				{
					id: 4,
					color: '#7a874f'
				},
				{
					id: 5,
					color: '#4b692f'
				},
				{
					id: 6,
					color: '#ac3232'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#4b692f'
				},
				{
					id: 9,
					color: '#1d5a22'
				},
				{
					id: 10,
					color: '#a16400'
				},
				{
					id: 11,
					color: '#0d4020'
				},
				{
					id: 12,
					color: '#0d4020'
				},
				{
					id: 13,
					color: '#02130c'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#02130c'
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5,
					color: '#02130c'
				},
				{
					id: 6,
					color: '#4b692f'
				},
				{
					id: 7,
					color: '#1d5a22'
				},
				{
					id: 8,
					color: '#0d4020'
				},
				{
					id: 9,
					color: '#0d4020'
				},
				{
					id: 10,
					color: '#0d4020'
				},
				{
					id: 11,
					color: '#02130c'
				},
				{
					id: 12,
					color: '#0e359e'
				},
				{
					id: 13,
					color: '#02130c'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#02130c'
				},
				{
					id: 5,
					color: '#7a874f'
				},
				{
					id: 6,
					color: '#fbf236'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#1d5a22'
				},
				{
					id: 9,
					color: '#1f76b4'
				},
				{
					id: 10,
					color: '#0d4020'
				},
				{
					id: 11,
					color: '#0d4020'
				},
				{
					id: 12,
					color: '#02130c'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 11,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#02130c'
				},
				{
					id: 4,
					color: '#7a874f'
				},
				{
					id: 5,
					color: '#4b692f'
				},
				{
					id: 6,
					color: '#4b692f'
				},
				{
					id: 7,
					color: '#1d5a22'
				},
				{
					id: 8,
					color: '#1d5a22'
				},
				{
					id: 9,
					color: '#0d4020'
				},
				{
					id: 10,
					color: '#650912'
				},
				{
					id: 11,
					color: '#0d4020'
				},
				{
					id: 12,
					color: '#0d4020'
				},
				{
					id: 13,
					color: '#02130c'
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		},
		{
			id: 12,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#02130c'
				},
				{
					id: 3,
					color: '#7a874f'
				},
				{
					id: 4,
					color: '#fbf236'
				},
				{
					id: 5,
					color: '#1d5a22'
				},
				{
					id: 6,
					color: '#1f76b4'
				},
				{
					id: 7,
					color: '#1d5a22'
				},
				{
					id: 8,
					color: '#0d4020'
				},
				{
					id: 9,
					color: '#0d4020'
				},
				{
					id: 10,
					color: '#0d4020'
				},
				{
					id: 11,
					color: '#a16400'
				},
				{
					id: 12,
					color: '#0d4020'
				},
				{
					id: 13,
					color: '#0d4020'
				},
				{
					id: 14,
					color: '#02130c'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 13,
			rows: [
				{
					id: 1,
					color: '#02130c'
				},
				{
					id: 2,
					color: '#7a874f'
				},
				{
					id: 3,
					color: '#1d5a22'
				},
				{
					id: 4,
					color: '#1d5a22'
				},
				{
					id: 5,
					color: '#1d5a22'
				},
				{
					id: 6,
					color: '#1d5a22'
				},
				{
					id: 7,
					color: '#0d4020'
				},
				{
					id: 8,
					color: '#650912'
				},
				{
					id: 9,
					color: '#0d4020'
				},
				{
					id: 10,
					color: '#0d4020'
				},
				{
					id: 11,
					color: '#0d4020'
				},
				{
					id: 12,
					color: '#0d4020'
				},
				{
					id: 13,
					color: '#0d4020'
				},
				{
					id: 14,
					color: '#0d4020'
				},
				{
					id: 15,
					color: '#02130c'
				}
			]
		},
		{
			id: 14,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#02130c'
				},
				{
					id: 3,
					color: '#5fcde4'
				},
				{
					id: 4,
					color: '#02130c'
				},
				{
					id: 5,
					color: '#02130c'
				},
				{
					id: 6,
					color: '#02130c'
				},
				{
					id: 7,
					color: '#622323'
				},
				{
					id: 8,
					color: '#471111'
				},
				{
					id: 9,
					color: '#471111'
				},
				{
					id: 10,
					color: '#02130c'
				},
				{
					id: 11,
					color: '#02130c'
				},
				{
					id: 12,
					color: '#650912'
				},
				{
					id: 13,
					color: '#02130c'
				},
				{
					id: 14,
					color: '#02130c'
				},
				{
					id: 15
				}
			]
		},
		{
			id: 15,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#02130c'
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#02130c'
				},
				{
					id: 7,
					color: '#663931'
				},
				{
					id: 8,
					color: '#622323'
				},
				{
					id: 9,
					color: '#471111'
				},
				{
					id: 10,
					color: '#02130c'
				},
				{
					id: 11
				},
				{
					id: 12,
					color: '#02130c'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				}
			]
		}
	]
}, {
	id: 8,
	name: 'reindeer',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#170303'
				},
				{
					id: 6
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15
				},
				{
					id: 16,
					color: '#170303'
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#170303'
				},
				{
					id: 5,
					color: '#eec39a'
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15,
					color: '#170303'
				},
				{
					id: 16,
					color: '#fbe1b6'
				},
				{
					id: 17,
					color: '#170303'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#170303'
				},
				{
					id: 4,
					color: '#fbe1b6'
				},
				{
					id: 5,
					color: '#e1a68c'
				},
				{
					id: 6,
					color: '#cb805e'
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14,
					color: '#170303'
				},
				{
					id: 15,
					color: '#fbe1b6'
				},
				{
					id: 16,
					color: '#eec39a'
				},
				{
					id: 17,
					color: '#cb805e'
				},
				{
					id: 18,
					color: '#170303'
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#170303'
				},
				{
					id: 3,
					color: '#fbe1b6'
				},
				{
					id: 4,
					color: '#e1a68c'
				},
				{
					id: 5,
					color: '#cb805e'
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12,
					color: '#e5edff'
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15,
					color: '#170303'
				},
				{
					id: 16,
					color: '#eec39a'
				},
				{
					id: 17,
					color: '#e1a68c'
				},
				{
					id: 18,
					color: '#cb805e'
				},
				{
					id: 19,
					color: '#170303'
				},
				{
					id: 20
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1,
					color: '#170303'
				},
				{
					id: 2,
					color: '#fbe1b6'
				},
				{
					id: 3,
					color: '#eec39a'
				},
				{
					id: 4,
					color: '#e1a68c'
				},
				{
					id: 5,
					color: '#cb805e'
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#170303'
				},
				{
					id: 11,
					color: '#e5edff'
				},
				{
					id: 12,
					color: '#aec7f0'
				},
				{
					id: 13,
					color: '#a0ade9'
				},
				{
					id: 14
				},
				{
					id: 15,
					color: '#170303'
				},
				{
					id: 16,
					color: '#eec39a'
				},
				{
					id: 17,
					color: '#e1a68c'
				},
				{
					id: 18,
					color: '#e1a68c'
				},
				{
					id: 19,
					color: '#cb805e'
				},
				{
					id: 20,
					color: '#170303'
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#170303'
				},
				{
					id: 3,
					color: '#170303'
				},
				{
					id: 4,
					color: '#eec39a'
				},
				{
					id: 5,
					color: '#cb805e'
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8
				},
				{
					id: 9,
					color: '#170303'
				},
				{
					id: 10,
					color: '#ac3232'
				},
				{
					id: 11,
					color: '#921421'
				},
				{
					id: 12,
					color: '#a0ade9'
				},
				{
					id: 13
				},
				{
					id: 14,
					color: '#170303'
				},
				{
					id: 15,
					color: '#170303'
				},
				{
					id: 16,
					color: '#eec39a'
				},
				{
					id: 17,
					color: '#cb805e'
				},
				{
					id: 18,
					color: '#170303'
				},
				{
					id: 19,
					color: '#170303'
				},
				{
					id: 20
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#170303'
				},
				{
					id: 4,
					color: '#eec39a'
				},
				{
					id: 5,
					color: '#e1a68c'
				},
				{
					id: 6,
					color: '#e1a68c'
				},
				{
					id: 7,
					color: '#e1a68c'
				},
				{
					id: 8,
					color: '#170303'
				},
				{
					id: 9,
					color: '#ac3232'
				},
				{
					id: 10,
					color: '#9e212e'
				},
				{
					id: 11,
					color: '#9e212e'
				},
				{
					id: 12,
					color: '#921421'
				},
				{
					id: 13,
					color: '#170303'
				},
				{
					id: 14,
					color: '#fbe1b6'
				},
				{
					id: 15,
					color: '#eec39a'
				},
				{
					id: 16,
					color: '#e1a68c'
				},
				{
					id: 17,
					color: '#cb805e'
				},
				{
					id: 18,
					color: '#170303'
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#170303'
				},
				{
					id: 5,
					color: '#eec39a'
				},
				{
					id: 6,
					color: '#cb805e'
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8,
					color: '#e5edff'
				},
				{
					id: 9,
					color: '#cbdbfc'
				},
				{
					id: 10,
					color: '#cbdbfc'
				},
				{
					id: 11,
					color: '#aec7f0'
				},
				{
					id: 12,
					color: '#aec7f0'
				},
				{
					id: 13,
					color: '#a0ade9'
				},
				{
					id: 14,
					color: '#170303'
				},
				{
					id: 15,
					color: '#e1a68c'
				},
				{
					id: 16,
					color: '#cb805e'
				},
				{
					id: 17,
					color: '#170303'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#170303'
				},
				{
					id: 6,
					color: '#eec39a'
				},
				{
					id: 7,
					color: '#cb805e'
				},
				{
					id: 8,
					color: '#663931'
				},
				{
					id: 9,
					color: '#692b2b'
				},
				{
					id: 10,
					color: '#692b2b'
				},
				{
					id: 11,
					color: '#692b2b'
				},
				{
					id: 12,
					color: '#692b2b'
				},
				{
					id: 13,
					color: '#561e1e'
				},
				{
					id: 14,
					color: '#e1a68c'
				},
				{
					id: 15,
					color: '#cb805e'
				},
				{
					id: 16,
					color: '#170303'
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#170303'
				},
				{
					id: 6,
					color: '#eec39a'
				},
				{
					id: 7,
					color: '#e1a68c'
				},
				{
					id: 8,
					color: '#805b4b'
				},
				{
					id: 9,
					color: '#663931'
				},
				{
					id: 10,
					color: '#663931'
				},
				{
					id: 11,
					color: '#663931'
				},
				{
					id: 12,
					color: '#692b2b'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#e1a68c'
				},
				{
					id: 15,
					color: '#cb805e'
				},
				{
					id: 16,
					color: '#170303'
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 11,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8,
					color: '#805b4b'
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#663931'
				},
				{
					id: 11,
					color: '#663931'
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#561e1e'
				},
				{
					id: 15,
					color: '#170303'
				},
				{
					id: 16
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 12,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8,
					color: '#805b4b'
				},
				{
					id: 9,
					color: '#805b4b'
				},
				{
					id: 10,
					color: '#663931'
				},
				{
					id: 11,
					color: '#663931'
				},
				{
					id: 12,
					color: '#663931'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#561e1e'
				},
				{
					id: 15,
					color: '#170303'
				},
				{
					id: 16
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 13,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7,
					color: '#805b4b'
				},
				{
					id: 8,
					color: '#805b4b'
				},
				{
					id: 9,
					color: '#663931'
				},
				{
					id: 10,
					color: '#ac3232'
				},
				{
					id: 11,
					color: '#ac3232'
				},
				{
					id: 12,
					color: '#663931'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#561e1e'
				},
				{
					id: 15,
					color: '#561e1e'
				},
				{
					id: 16,
					color: '#170303'
				},
				{
					id: 17,
					color: '#170303'
				},
				{
					id: 18,
					color: '#170303'
				},
				{
					id: 19,
					color: '#170303'
				},
				{
					id: 20,
					color: '#170303'
				}
			]
		},
		{
			id: 14,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7,
					color: '#805b4b'
				},
				{
					id: 8,
					color: '#663931'
				},
				{
					id: 9,
					color: '#ac3232'
				},
				{
					id: 10,
					color: '#ffffff'
				},
				{
					id: 11,
					color: '#9e212e'
				},
				{
					id: 12,
					color: '#921421'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#561e1e'
				},
				{
					id: 15,
					color: '#561e1e'
				},
				{
					id: 16,
					color: '#561e1e'
				},
				{
					id: 17,
					color: '#561e1e'
				},
				{
					id: 18,
					color: '#561e1e'
				},
				{
					id: 19,
					color: '#561e1e'
				},
				{
					id: 20,
					color: '#561e1e'
				}
			]
		},
		{
			id: 15,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7,
					color: '#805b4b'
				},
				{
					id: 8,
					color: '#663931'
				},
				{
					id: 9,
					color: '#9e212e'
				},
				{
					id: 10,
					color: '#9e212e'
				},
				{
					id: 11,
					color: '#9e212e'
				},
				{
					id: 12,
					color: '#921421'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#561e1e'
				},
				{
					id: 15,
					color: '#561e1e'
				},
				{
					id: 16,
					color: '#561e1e'
				},
				{
					id: 17,
					color: '#561e1e'
				},
				{
					id: 18,
					color: '#561e1e'
				},
				{
					id: 19,
					color: '#561e1e'
				},
				{
					id: 20,
					color: '#561e1e'
				}
			]
		},
		{
			id: 16,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#170303'
				},
				{
					id: 5,
					color: '#170303'
				},
				{
					id: 6,
					color: '#4b692f'
				},
				{
					id: 7,
					color: '#805b4b'
				},
				{
					id: 8,
					color: '#663931'
				},
				{
					id: 9,
					color: '#663931'
				},
				{
					id: 10,
					color: '#9e212e'
				},
				{
					id: 11,
					color: '#921421'
				},
				{
					id: 12,
					color: '#692b2b'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#561e1e'
				},
				{
					id: 15,
					color: '#692b2b'
				},
				{
					id: 16,
					color: '#561e1e'
				},
				{
					id: 17,
					color: '#692b2b'
				},
				{
					id: 18,
					color: '#561e1e'
				},
				{
					id: 19,
					color: '#561e1e'
				},
				{
					id: 20,
					color: '#561e1e'
				}
			]
		},
		{
			id: 17,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#170303'
				},
				{
					id: 4,
					color: '#788748'
				},
				{
					id: 5,
					color: '#1f3c10'
				},
				{
					id: 6,
					color: '#170303'
				},
				{
					id: 7,
					color: '#4b692f'
				},
				{
					id: 8,
					color: '#663931'
				},
				{
					id: 9,
					color: '#663931'
				},
				{
					id: 10,
					color: '#663931'
				},
				{
					id: 11,
					color: '#692b2b'
				},
				{
					id: 12,
					color: '#692b2b'
				},
				{
					id: 13,
					color: '#561e1e'
				},
				{
					id: 14,
					color: '#1f3c10'
				},
				{
					id: 15,
					color: '#692b2b'
				},
				{
					id: 16,
					color: '#692b2b'
				},
				{
					id: 17,
					color: '#561e1e'
				},
				{
					id: 18,
					color: '#692b2b'
				},
				{
					id: 19,
					color: '#561e1e'
				},
				{
					id: 20,
					color: '#561e1e'
				}
			]
		},
		{
			id: 18,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#170303'
				},
				{
					id: 3,
					color: '#788748'
				},
				{
					id: 4,
					color: '#1f3c10'
				},
				{
					id: 5,
					color: '#170303'
				},
				{
					id: 6,
					color: '#788748'
				},
				{
					id: 7,
					color: '#1f3c10'
				},
				{
					id: 8,
					color: '#170303'
				},
				{
					id: 9,
					color: '#170303'
				},
				{
					id: 10,
					color: '#561e1e'
				},
				{
					id: 11,
					color: '#561e1e'
				},
				{
					id: 12,
					color: '#663931'
				},
				{
					id: 13,
					color: '#692b2b'
				},
				{
					id: 14,
					color: '#692b2b'
				},
				{
					id: 15,
					color: '#1f3c10'
				},
				{
					id: 16,
					color: '#663931'
				},
				{
					id: 17,
					color: '#692b2b'
				},
				{
					id: 18,
					color: '#561e1e'
				},
				{
					id: 19,
					color: '#692b2b'
				},
				{
					id: 20,
					color: '#561e1e'
				}
			]
		},
		{
			id: 19,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#170303'
				},
				{
					id: 3,
					color: '#1f3c10'
				},
				{
					id: 4,
					color: '#170303'
				},
				{
					id: 5,
					color: '#788748'
				},
				{
					id: 6,
					color: '#1f3c10'
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#170303'
				},
				{
					id: 11,
					color: '#805b4b'
				},
				{
					id: 12,
					color: '#663931'
				},
				{
					id: 13,
					color: '#663931'
				},
				{
					id: 14,
					color: '#663931'
				},
				{
					id: 15,
					color: '#4b692f'
				},
				{
					id: 16,
					color: '#692b2b'
				},
				{
					id: 17,
					color: '#663931'
				},
				{
					id: 18,
					color: '#692b2b'
				},
				{
					id: 19,
					color: '#561e1e'
				},
				{
					id: 20,
					color: '#692b2b'
				}
			]
		},
		{
			id: 20,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#170303'
				},
				{
					id: 4,
					color: '#170303'
				},
				{
					id: 5,
					color: '#4b692f'
				},
				{
					id: 6,
					color: '#1f3c10'
				},
				{
					id: 7,
					color: '#170303'
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#170303'
				},
				{
					id: 11,
					color: '#805b4b'
				},
				{
					id: 12,
					color: '#663931'
				},
				{
					id: 13,
					color: '#663931'
				},
				{
					id: 14,
					color: '#663931'
				},
				{
					id: 15,
					color: '#663931'
				},
				{
					id: 16,
					color: '#663931'
				},
				{
					id: 17,
					color: '#692b2b'
				},
				{
					id: 18,
					color: '#692b2b'
				},
				{
					id: 19,
					color: '#692b2b'
				},
				{
					id: 20,
					color: '#692b2b'
				}
			]
		}
	]
}, {
	id: 9,
	name: 'yeti',
	cols: [
		{
			id: 1,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7
				},
				{
					id: 8
				},
				{
					id: 9
				},
				{
					id: 10
				},
				{
					id: 11
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14
				},
				{
					id: 15,
					color: '#000000'
				},
				{
					id: 16
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 2,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#3d4b4f'
				},
				{
					id: 7,
					color: '#000000'
				},
				{
					id: 8,
					color: '#000000'
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#000000'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12
				},
				{
					id: 13
				},
				{
					id: 14,
					color: '#000000'
				},
				{
					id: 15,
					color: '#3d4b4f'
				},
				{
					id: 16,
					color: '#000000'
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 3,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#3d4b4f'
				},
				{
					id: 6,
					color: '#0f151e'
				},
				{
					id: 7,
					color: '#e7f4f4'
				},
				{
					id: 8,
					color: '#ced9dd'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10,
					color: '#e7f4f4'
				},
				{
					id: 11,
					color: '#ced9dd'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13
				},
				{
					id: 14,
					color: '#000000'
				},
				{
					id: 15,
					color: '#3d4b4f'
				},
				{
					id: 16,
					color: '#0f151e'
				},
				{
					id: 17,
					color: '#000000'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 4,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#000000'
				},
				{
					id: 4,
					color: '#3d4b4f'
				},
				{
					id: 5,
					color: '#293439'
				},
				{
					id: 6,
					color: '#e7f4f4'
				},
				{
					id: 7,
					color: '#ced9dd'
				},
				{
					id: 8,
					color: '#ced9dd'
				},
				{
					id: 9,
					color: '#8ca9c7'
				},
				{
					id: 10,
					color: '#ced9dd'
				},
				{
					id: 11,
					color: '#ced9dd'
				},
				{
					id: 12,
					color: '#8ca9c7'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14,
					color: '#000000'
				},
				{
					id: 15,
					color: '#000000'
				},
				{
					id: 16,
					color: '#293439'
				},
				{
					id: 17,
					color: '#0f151e'
				},
				{
					id: 18,
					color: '#000000'
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 5,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#3d4b4f'
				},
				{
					id: 6,
					color: '#ced9dd'
				},
				{
					id: 7,
					color: '#ced9dd'
				},
				{
					id: 8,
					color: '#8ca9c7'
				},
				{
					id: 9,
					color: '#8ca9c7'
				},
				{
					id: 10,
					color: '#ced9dd'
				},
				{
					id: 11,
					color: '#8ca9c7'
				},
				{
					id: 12,
					color: '#ced9dd'
				},
				{
					id: 13,
					color: '#8ca9c7'
				},
				{
					id: 14,
					color: '#8ca9c7'
				},
				{
					id: 15,
					color: '#000000'
				},
				{
					id: 16,
					color: '#293439'
				},
				{
					id: 17,
					color: '#000000'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 6,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#293439'
				},
				{
					id: 6,
					color: '#0f151e'
				},
				{
					id: 7,
					color: '#8ca9c7'
				},
				{
					id: 8,
					color: '#8ca9c7'
				},
				{
					id: 9,
					color: '#ced9dd'
				},
				{
					id: 10,
					color: '#ced9dd'
				},
				{
					id: 11,
					color: '#ced9dd'
				},
				{
					id: 12,
					color: '#8ca9c7'
				},
				{
					id: 13,
					color: '#8ca9c7'
				},
				{
					id: 14,
					color: '#8ca9c7'
				},
				{
					id: 15,
					color: '#293439'
				},
				{
					id: 16,
					color: '#0f151e'
				},
				{
					id: 17,
					color: '#000000'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 7,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#293439'
				},
				{
					id: 7,
					color: '#3e5080'
				},
				{
					id: 8,
					color: '#293439'
				},
				{
					id: 9,
					color: '#0f151e'
				},
				{
					id: 10,
					color: '#3e5080'
				},
				{
					id: 11,
					color: '#293439'
				},
				{
					id: 12,
					color: '#0f151e'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#8ca9c7'
				},
				{
					id: 15,
					color: '#0f151e'
				},
				{
					id: 16,
					color: '#000000'
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 8,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#6a8292'
				},
				{
					id: 8,
					color: '#60758b'
				},
				{
					id: 9,
					color: '#60758b'
				},
				{
					id: 10,
					color: '#60758b'
				},
				{
					id: 11,
					color: '#60758b'
				},
				{
					id: 12,
					color: '#3e5080'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#8ca9c7'
				},
				{
					id: 15,
					color: '#8ca9c7'
				},
				{
					id: 16,
					color: '#000000'
				},
				{
					id: 17
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 9,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#6a8292'
				},
				{
					id: 8,
					color: '#ffffff'
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#60758b'
				},
				{
					id: 11,
					color: '#ffffff'
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#8ca9c7'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#60758b'
				},
				{
					id: 17,
					color: '#000000'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 10,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#ced9dd'
				},
				{
					id: 7,
					color: '#6a8292'
				},
				{
					id: 8,
					color: '#ffffff'
				},
				{
					id: 9
				},
				{
					id: 10,
					color: '#60758b'
				},
				{
					id: 11,
					color: '#ffffff'
				},
				{
					id: 12
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#ced9dd'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#60758b'
				},
				{
					id: 17,
					color: '#3e5080'
				},
				{
					id: 18,
					color: '#000000'
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 11,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#8ca9c7'
				},
				{
					id: 7,
					color: '#6a8292'
				},
				{
					id: 8,
					color: '#60758b'
				},
				{
					id: 9,
					color: '#3d4b4f'
				},
				{
					id: 10,
					color: '#3d4b4f'
				},
				{
					id: 11,
					color: '#60758b'
				},
				{
					id: 12,
					color: '#60758b'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#ced9dd'
				},
				{
					id: 15,
					color: '#8ca9c7'
				},
				{
					id: 16,
					color: '#3e5080'
				},
				{
					id: 17,
					color: '#3e5080'
				},
				{
					id: 18,
					color: '#000000'
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 12,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#6a8292'
				},
				{
					id: 8,
					color: '#293439'
				},
				{
					id: 9,
					color: '#293439'
				},
				{
					id: 10,
					color: '#293439'
				},
				{
					id: 11,
					color: '#0f151e'
				},
				{
					id: 12,
					color: '#3e5080'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#ced9dd'
				},
				{
					id: 15,
					color: '#8ca9c7'
				},
				{
					id: 16,
					color: '#3e5080'
				},
				{
					id: 17,
					color: '#000000'
				},
				{
					id: 18
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 13,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5
				},
				{
					id: 6,
					color: '#000000'
				},
				{
					id: 7,
					color: '#6a8292'
				},
				{
					id: 8,
					color: '#60758b'
				},
				{
					id: 9,
					color: '#0f151e'
				},
				{
					id: 10,
					color: '#0f151e'
				},
				{
					id: 11,
					color: '#3e5080'
				},
				{
					id: 12,
					color: '#3e5080'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#3e5080'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#8ca9c7'
				},
				{
					id: 17,
					color: '#000000'
				},
				{
					id: 18,
					color: '#000000'
				},
				{
					id: 19
				},
				{
					id: 20
				}
			]
		},
		{
			id: 14,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4
				},
				{
					id: 5,
					color: '#000000'
				},
				{
					id: 6,
					color: '#6a8292'
				},
				{
					id: 7,
					color: '#ffffff'
				},
				{
					id: 8,
					color: '#60758b'
				},
				{
					id: 9,
					color: '#3e5080'
				},
				{
					id: 10,
					color: '#3e5080'
				},
				{
					id: 11,
					color: '#ffffff'
				},
				{
					id: 12,
					color: '#ffffff'
				},
				{
					id: 13,
					color: '#000000'
				},
				{
					id: 14,
					color: '#3e5080'
				},
				{
					id: 15,
					color: '#8ca9c7'
				},
				{
					id: 16,
					color: '#ced9dd'
				},
				{
					id: 17,
					color: '#ced9dd'
				},
				{
					id: 18,
					color: '#8ca9c7'
				},
				{
					id: 19,
					color: '#000000'
				},
				{
					id: 20,
					color: '#000000'
				}
			]
		},
		{
			id: 15,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#e7f4f4'
				},
				{
					id: 6,
					color: '#6a8292'
				},
				{
					id: 7,
					color: '#000000'
				},
				{
					id: 8,
					color: '#000000'
				},
				{
					id: 9,
					color: '#000000'
				},
				{
					id: 10,
					color: '#000000'
				},
				{
					id: 11,
					color: '#000000'
				},
				{
					id: 12,
					color: '#000000'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#3e5080'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#ced9dd'
				},
				{
					id: 17,
					color: '#8ca9c7'
				},
				{
					id: 18,
					color: '#ced9dd'
				},
				{
					id: 19,
					color: '#8ca9c7'
				},
				{
					id: 20,
					color: '#8ca9c7'
				}
			]
		},
		{
			id: 16,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3
				},
				{
					id: 4,
					color: '#000000'
				},
				{
					id: 5,
					color: '#e7f4f4'
				},
				{
					id: 6,
					color: '#6a8292'
				},
				{
					id: 7,
					color: '#60758b'
				},
				{
					id: 8,
					color: '#60758b'
				},
				{
					id: 9,
					color: '#60758b'
				},
				{
					id: 10,
					color: '#60758b'
				},
				{
					id: 11,
					color: '#60758b'
				},
				{
					id: 12,
					color: '#3e5080'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#3e5080'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#8ca9c7'
				},
				{
					id: 17,
					color: '#ced9dd'
				},
				{
					id: 18,
					color: '#8ca9c7'
				},
				{
					id: 19,
					color: '#8ca9c7'
				},
				{
					id: 20,
					color: '#8ca9c7'
				}
			]
		},
		{
			id: 17,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#000000'
				},
				{
					id: 4,
					color: '#e7f4f4'
				},
				{
					id: 5,
					color: '#ced9dd'
				},
				{
					id: 6,
					color: '#60758b'
				},
				{
					id: 7,
					color: '#60758b'
				},
				{
					id: 8,
					color: '#3e5080'
				},
				{
					id: 9,
					color: '#ced9dd'
				},
				{
					id: 10,
					color: '#3e5080'
				},
				{
					id: 11,
					color: '#3e5080'
				},
				{
					id: 12,
					color: '#3e5080'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#3e5080'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#ced9dd'
				},
				{
					id: 17,
					color: '#8ca9c7'
				},
				{
					id: 18,
					color: '#8ca9c7'
				},
				{
					id: 19,
					color: '#8ca9c7'
				},
				{
					id: 20,
					color: '#8ca9c7'
				}
			]
		},
		{
			id: 18,
			rows: [
				{
					id: 1
				},
				{
					id: 2
				},
				{
					id: 3,
					color: '#000000'
				},
				{
					id: 4,
					color: '#e7f4f4'
				},
				{
					id: 5,
					color: '#ced9dd'
				},
				{
					id: 6,
					color: '#ced9dd'
				},
				{
					id: 7,
					color: '#3e5080'
				},
				{
					id: 8,
					color: '#ced9dd'
				},
				{
					id: 9,
					color: '#ced9dd'
				},
				{
					id: 10,
					color: '#3e5080'
				},
				{
					id: 11,
					color: '#3e5080'
				},
				{
					id: 12,
					color: '#3e5080'
				},
				{
					id: 13,
					color: '#3e5080'
				},
				{
					id: 14,
					color: '#ced9dd'
				},
				{
					id: 15,
					color: '#8ca9c7'
				},
				{
					id: 16,
					color: '#8ca9c7'
				},
				{
					id: 17,
					color: '#ced9dd'
				},
				{
					id: 18,
					color: '#8ca9c7'
				},
				{
					id: 19,
					color: '#8ca9c7'
				},
				{
					id: 20,
					color: '#8ca9c7'
				}
			]
		},
		{
			id: 19,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#000000'
				},
				{
					id: 3,
					color: '#e7f4f4'
				},
				{
					id: 4,
					color: '#ced9dd'
				},
				{
					id: 5,
					color: '#ced9dd'
				},
				{
					id: 6,
					color: '#ced9dd'
				},
				{
					id: 7,
					color: '#8ca9c7'
				},
				{
					id: 8,
					color: '#ced9dd'
				},
				{
					id: 9,
					color: '#ced9dd'
				},
				{
					id: 10,
					color: '#8ca9c7'
				},
				{
					id: 11,
					color: '#ced9dd'
				},
				{
					id: 12,
					color: '#ced9dd'
				},
				{
					id: 13,
					color: '#ced9dd'
				},
				{
					id: 14,
					color: '#8ca9c7'
				},
				{
					id: 15,
					color: '#ced9dd'
				},
				{
					id: 16,
					color: '#ced9dd'
				},
				{
					id: 17,
					color: '#8ca9c7'
				},
				{
					id: 18,
					color: '#8ca9c7'
				},
				{
					id: 19,
					color: '#8ca9c7'
				},
				{
					id: 20,
					color: '#8ca9c7'
				}
			]
		},
		{
			id: 20,
			rows: [
				{
					id: 1
				},
				{
					id: 2,
					color: '#000000'
				},
				{
					id: 3,
					color: '#e7f4f4'
				},
				{
					id: 4,
					color: '#ced9dd'
				},
				{
					id: 5,
					color: '#ced9dd'
				},
				{
					id: 6,
					color: '#ced9dd'
				},
				{
					id: 7,
					color: '#ced9dd'
				},
				{
					id: 8,
					color: '#ced9dd'
				},
				{
					id: 9,
					color: '#8ca9c7'
				},
				{
					id: 10,
					color: '#ced9dd'
				},
				{
					id: 11,
					color: '#ced9dd'
				},
				{
					id: 12,
					color: '#8ca9c7'
				},
				{
					id: 13,
					color: '#8ca9c7'
				},
				{
					id: 14,
					color: '#ced9dd'
				},
				{
					id: 15,
					color: '#8ca9c7'
				},
				{
					id: 16,
					color: '#8ca9c7'
				},
				{
					id: 17,
					color: '#8ca9c7'
				},
				{
					id: 18,
					color: '#8ca9c7'
				},
				{
					id: 19,
					color: '#8ca9c7'
				},
				{
					id: 20,
					color: '#8ca9c7'
				}
			]
		}
	]
}]

for (const nonogram of PUZZLES) {
	for (let i = 0; i < nonogram.cols.length; i++) {
		for (let j = 0; j < nonogram.cols[i].rows.length; j++) {
			nonogram.cols[i].rows[j].id = j + 1
		}
		
		nonogram.cols[i].id = i + 1
	}
}

/**
 * Puzzlereferenzen
 */
class Nonogramms {
	private static PUZZLES = PUZZLES
	public EMPTY_PUZZLE: Nonogramm = {
		id: -1,
		name: 'empty',
		cols: []
	}

	/**
	 * Findet das entsprechende Puzzle anhand der übergebenen Id
	 *
	 * @param id eindeutiger Identifier des Puzzles
	 * @returns Nonogramm mit übergebener Id
	 */
	public getPuzzleById (id: number): Nonogramm {
		const foundPuzzle: Nonogramm | undefined = Nonogramms.PUZZLES.find(puzzle => puzzle.id === id) as Nonogramm
		
		if (foundPuzzle) {
			return foundPuzzle
		} else {
			return this.EMPTY_PUZZLE
		}
	}

	/**
	 * gibt Metainformationen der Nonogramme als Array zurück
	 *
	 * @returns Array mit Objekten, die jeweils die id, den Namen und die Anzahl der Zeilen und Spalten der Puzzle enthalten
	 */
	public getPuzzleInfos (): NonogrammInfo[] {
		return Nonogramms.PUZZLES.map(getPuzzleInfo)
	}
	
	/**
	 * gibt an, ob das zur übergebenen Id gehörende Puzzle das letzte in der Liste ist
	 * @param id eindeutiger Identifier des zu prüfenden Nonogramms
	 *
	 * @returns boolean wenn true, handelt es sich um das letzte Nonogramm in der Liste
	 */
	public isLastPuzzle (id: number): boolean {
		return (Nonogramms.PUZZLES.indexOf(this.getPuzzleById(id)) + 1 === Nonogramms.PUZZLES.length)
	}

	/**
	 * Gibt den Namen des Puzzles in Deutsch zurück
	 *
	 * @param title Name des zu übersetzenden Puzzles
	 * @returns string deutsche Bezeichnung des Puzzles
	 */
	getGermanName (title: string): string {
		switch (title) {
		case 'penguin': return 'Pinguin'
		case 'baubel': return 'Baumkugel'
		case 'elf': return 'Elfe'
		case 'reindeer': return 'Rentier'
		case 'tree': return 'Christbaum'
		case 'candles': return 'Kerzen'
		case 'cat': return 'Katze'
		case 'present': return 'Geschenk'
		case 'yeti': return 'Yeti'
		default: return 'N/A'
		}
	}
}

const nonogramms = new Nonogramms()

export default nonogramms
