
import { Options, Vue } from 'vue-class-component'

import Headline from '@/components/Headline.vue'
import BreakLine from '@/components/BreakLine.vue'
import NavigationButton from '@/components/NavigationButton.vue'
import Modal from '@/components/Modal.vue'

import store from '@/store'

import Tracker from '@/utils/CreataleTracker'

@Options({
	components: {
		Modal,
		NavigationButton,
		BreakLine,
		Headline
	}
})
export default class PrivacyPolicy extends Vue {
	privacyStatementChecked = store.state.gdpr.privacyPolicyAccepted
	fullAgeChecked = store.state.gdpr.fullAgeAccepted
	gameAnalyticsChecked = store.state.gdpr.gameAnalyticsAccepted
	showText = false

	get disableContinue (): boolean {
		return !this.privacyStatementChecked || !this.fullAgeChecked
	}

	get trackerTarget (): string {
		return Tracker.target()
	}

	handleGdpr (): void {
		// TODO: this should be this.$store, but some shit is not working
		store.dispatch('gdpr/updateDataPrivacyStatus', {
			privacyPolicyAccepted: this.privacyStatementChecked,
			gameAnalyticsAccepted: this.gameAnalyticsChecked,
			onlineHighscoreAccepted: false,
			fullAgeAccepted: this.fullAgeChecked
		})
		this.$router.push('/')
	}
}
