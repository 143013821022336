import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'

import Vue3TouchEvents from 'vue3-touch-events'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
	faArrowRotateLeft,
	faBars,
	faChevronLeft,
	faChevronRight,
	faCircleInfo
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faArrowRotateLeft, faBars, faChevronRight, faChevronLeft, faCircleInfo)

createApp(App).component('font-awesome-icon', FontAwesomeIcon)
	.use(store)
	.use(router)
	.use(Vue3TouchEvents, { disableClick: false, dragFrequency: 25 })
	.mount('#app')
