import { v4 as uuid } from 'uuid'
import Bowser from 'bowser'

interface TrackerOptions {
	server: string
	authKey: string
	gameId: string
	maxQueueLength?: number
	localStoragePrefix: string
	userId: string
	pollInterval?: number
}

interface EventConfig {
	type: string
	subType?: string
	value?: string
	doubleValue?: number
}

interface TrackerEvent {
	eventType: string
	eventSubType?: string
	value?: string
	doubleValue?: number
	userId: string
	sessionId: string
	clientTimestamp: number
	sequenceNumber: number
}

class CreataleTracker {
	sequenceNumber = 0
	sessionId = uuid()
	timer: (ReturnType<typeof setInterval> | null) = null
	sending = false
	enabled = false
	initialized = false
	localStorageKey = ''
	localStorageSendKey = ''
	eventQueue: TrackerEvent[] = []
	server = ''
	authKey = ''
	gameId = ''
	userId = ''
	pollInterval = 1000
	maxQueueLength = 100
	trackerUrl = ''

	constructor () {
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'hidden') {
				this.sendBeacon('SESSION_END')
			}

			if (document.visibilityState === 'visible') {
				this.sendBeacon('SESSION_START')
			}
		})
	}

	initialize (options: TrackerOptions): void {
		if (!this.initialized) {
			const {
				server,
				authKey,
				gameId,
				maxQueueLength = 100,
				localStoragePrefix,
				userId,
				pollInterval = 1000
			} = options

			this.localStorageKey = localStoragePrefix + '__tracking-queue'
			this.localStorageSendKey = localStoragePrefix + '__tracking-send-queue'
			this.eventQueue
				= JSON.parse(localStorage.getItem(this.localStorageKey) || '[]')
					.concat(JSON.parse(localStorage.getItem(this.localStorageSendKey) || '[]'))
					.slice(-maxQueueLength) as TrackerEvent[]
			this.server = server
			this.authKey = authKey
			this.gameId = gameId
			this.userId = userId
			this.pollInterval = pollInterval
			this.maxQueueLength = maxQueueLength
			this.trackerUrl = this.server + '/tracker/' + this.gameId
			this.initialized = true
		}
	}

	sendBeacon (type: string): void {
		if (this.initialized && this.enabled) {
			const clientInfo = Bowser.parse(window.navigator.userAgent)
			const { browser, os, platform, engine } = clientInfo

			const eventData = JSON.stringify({
				authKey: this.authKey,
				clientInfo: {
					sessionId: this.sessionId,
					userId: this.userId,
					browser,
					os,
					platform,
					engine
				},
				events: [this.createEvent({ type })]
			})
			const headers = { type: 'application/json;charset=UTF-8' }
			const blob = new Blob([eventData], headers)
			navigator.sendBeacon(this.trackerUrl, blob)
		}
	}

	enable (): void {
		if (this.initialized && !this.enabled) {
			console.log('CreataleTracker', 'enabled')
			this.enabled = true
			this.sendBeacon('SESSION_START')
			this.start()
		}
	}

	disable (): void {
		if (this.initialized && this.enabled) {
			console.log('CreataleTracker', 'disabled')
			this.sendBeacon('SESSION_END')
			this.stop()
			this.enabled = false
		}
	}

	start (): void {
		if (this.initialized && this.enabled) {
			this.sendQueue()
			this.timer = setInterval(() => this.sendQueue(), this.pollInterval)
		}
	}

	stop (): void {
		if (this.initialized && this.enabled && this.timer) {
			clearInterval(this.timer)
			this.timer = null
		}
	}

	sendQueue (): void {
		if (this.initialized && this.enabled && !this.sending && this.eventQueue.length > 0) {
			const httpRequest = new XMLHttpRequest()

			if (httpRequest) {
				let sendQueue = this.eventQueue
				this.eventQueue = []
				localStorage.setItem(this.localStorageSendKey, JSON.stringify(sendQueue))
				localStorage.setItem(this.localStorageKey, JSON.stringify(this.eventQueue))

				httpRequest.onreadystatechange = (): void => {
					if (httpRequest.readyState === XMLHttpRequest.DONE) {
						if (sendQueue.length > 0) {
							if (httpRequest.status !== 200) {
								this.eventQueue = sendQueue.concat(this.eventQueue)
								if (this.eventQueue.length > this.maxQueueLength) {
									this.eventQueue = this.eventQueue.slice(-this.maxQueueLength)
								}
								localStorage.setItem(this.localStorageKey, JSON.stringify(this.eventQueue))
							}
							sendQueue = []
							localStorage.setItem(this.localStorageSendKey, '[]')
						}
						this.sending = false
					}
				}
				httpRequest.ontimeout = (): void => {
					if (sendQueue.length > 0) {
						this.eventQueue = sendQueue.concat(this.eventQueue)
						sendQueue = []
						if (this.eventQueue.length > this.maxQueueLength) {
							this.eventQueue = this.eventQueue.slice(-this.maxQueueLength)
						}
						localStorage.setItem(this.localStorageKey, JSON.stringify(this.eventQueue))
						localStorage.setItem(this.localStorageSendKey, '[]')
					}
					this.sending = false
				}
				httpRequest.onerror = (): void => {
					if (sendQueue.length > 0) {
						this.eventQueue = sendQueue.concat(this.eventQueue)
						sendQueue = []
						if (this.eventQueue.length > this.maxQueueLength) {
							this.eventQueue = this.eventQueue.slice(-this.maxQueueLength)
						}
						localStorage.setItem(this.localStorageKey, JSON.stringify(this.eventQueue))
						localStorage.setItem(this.localStorageSendKey, '[]')
					}
					this.sending = false
				}
				httpRequest.open('POST', this.trackerUrl)
				httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
				this.sending = true
				httpRequest.send(JSON.stringify({
					authKey: this.authKey,
					events: sendQueue
				}))
			}
		}
	}

	createEvent (event: EventConfig): TrackerEvent {
		const { type: eventType, subType: eventSubType, value, doubleValue } = event
		const result = {
			eventType,
			eventSubType,
			value,
			doubleValue,
			userId: this.userId,
			sessionId: this.sessionId,
			clientTimestamp: Date.now(),
			sequenceNumber: this.sequenceNumber
		}
		this.sequenceNumber++
		return result
	}

	addEvent (event: EventConfig): void {
		if (this.initialized && this.enabled) {
			this.eventQueue.push(this.createEvent(event))
			if (this.eventQueue.length > this.maxQueueLength) {
				this.eventQueue = this.eventQueue.slice(-this.maxQueueLength)
			}
			localStorage.setItem(this.localStorageKey, JSON.stringify(this.eventQueue))
		}
	}

	createUserId (): string {
		return uuid()
	}

	target (): string {
		return 'creatale'
	}
}

const creataleTrackerInstance = new CreataleTracker()

export default creataleTrackerInstance
