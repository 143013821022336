
import { Options, prop, Vue } from 'vue-class-component'

import NumberBlock from '@/components/NumberBlock.vue'

class BlockNumbersProps {
	blockNumbers = prop<number[][]>({
		required: true
	})
	
	smallBlocks = false
}

@Options({
	components: {
		NumberBlock
	}
})
export default class BlockNumbers extends Vue.with(BlockNumbersProps) {
}
