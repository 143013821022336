
import { prop, Vue } from 'vue-class-component'

import config from '@/config'

interface Level {
	rows: number
	cols: number
	name: string
	id: number
	hasBeenSolved: boolean
}

class LevelButtonProps {
	level = prop<Level>({
		required: true
	})
}

export default class LevelButton extends Vue.with(LevelButtonProps) {
	get title (): string {
		const { rows, cols } = this.level
		
		return `${rows}x${cols}`
	}
	
	get imagePath (): string {
		const { hasBeenSolved, name } = this.level
		
		return `${config.publicPath}img/puzzleSolutions/${hasBeenSolved ? name : 'questionMark'}.png`
	}
	
	get navigationPath (): string {
		return `play/${this.level.id}`
	}
}
