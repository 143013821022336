
import { Options, prop, Vue } from 'vue-class-component'

import DragTracker from '@/models/DragTracker'

import MarkingDimensions from '@/types/MarkingDimensions'

class SlabSelectionProps {
	dragTracker = prop<DragTracker>({
		required: true
	})
}

@Options({
})
export default class SlabSelection extends Vue.with(SlabSelectionProps) {
	/**
	 * ermittelt die Maße und Positionen für das Markierungsfeld inkl. dem Infofenster mit der aktuellen Anzahl,
	 * wenn mehrere Felder angewählt werden
	 */
	get markingDimension (): MarkingDimensions {
		return this.dragTracker.markingDimension
	}
	
	get dragHintStyle (): Record<string, unknown> {
		return {
			...this.markingDimension.hint,
			color: this.dragTracker.isTracking ? 'azure' : 'red'
		}
	}
}
