
import { ClassComponentHooks, Options, Vue } from 'vue-class-component'

import Modal from '@/components/Modal.vue'
import NavigationButton from '@/components/NavigationButton.vue'
import PuzzleSolved from '@/components/PuzzleSolved.vue'
import BreakLine from '@/components/BreakLine.vue'
import HowToPlay from '@/components/HowToPlay.vue'
import GameGridComponent from '@/components/GameGrid.vue'

import Tracker from '@/utils/CreataleTracker'

import Nonogramm from '@/models/Nonogramm'

import config from '@/config'
import store from '@/store'

@Options<GameGrid>({
	components: {
		HowToPlay,
		BreakLine,
		PuzzleSolved,
		NavigationButton,
		Modal,
		GameGrid: GameGridComponent
	},
	watch: {
		isSolved (newValue: boolean, oldValue: boolean): void {
			if (newValue && newValue !== oldValue) {
				Tracker.addEvent({
					type: 'PUZZLE_SOLVED',
					value: '' + this.puzzle.id
				})
			}
		}
	}
})
export default class GameGrid extends Vue implements ClassComponentHooks {
	/**
	 * gibt an, ob die Spielanleitung eingeblendet wird
	 */
	showHowTo = false

	/**
	 * zur Anzeige der deutschen Bezeichnung des Puzzles auf dem Lösungsscreen
	 */
	get germanTitle (): string {
		return store.getters['nonogramms/germanTitle']
	}
	
	get puzzleImagePath (): string {
		return `${config.publicPath}img/puzzleSolutions/${this.puzzle.name}.png`
	}
	
	get isLastLevel (): boolean {
		return store.getters['nonogramms/isLastLevel']
	}
	
	get puzzle (): Nonogramm {
		return store.state.nonogramms.currentSolution
	}
	
	get isSolved (): boolean {
		return store.getters['nonogramms/isSolved']
	}

	/**
	 * setzt die aktuelle Lösung zurück und leert alle Felder des Spielfeldes
	 */
	onResetPuzzle (trackEvent = true): void {
		console.log('onResetPuzzle')
		if (trackEvent) {
			Tracker.addEvent({
				type: 'UI_INTERACTION',
				subType: 'REST_PUZZLE'
			})
		}
		
		store.dispatch('nonogramms/resetPuzzle')
	}

	/**
	 * wird ausgelöst, wenn die Spielanleitung angezeigt werden soll
	 */
	onShowHowTo (): void {
		Tracker.addEvent({
			type: 'UI_INTERACTION',
			subType: 'SHOW_HOW_TO'
		})
		this.showHowTo = true
	}

	closeHowTo (): void {
		Tracker.addEvent({
			type: 'UI_INTERACTION',
			subType: 'CLOSE_HOW_TO'
		})
		this.showHowTo = false
	}
}
