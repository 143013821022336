
import { Options, Vue } from 'vue-class-component'

import Headline from '@/components/Headline.vue'
import BreakLine from '@/components/BreakLine.vue'
import NumberBlock from '@/components/NumberBlock.vue'
import Slab from '@/components/Slab.vue'
import GameGrid from '@/components/GameGrid.vue'

import Nonogramm from '@/models/Nonogramm'
import SlabStateEnum from '@/models/SlabStateEnum'

interface NonogrammSpec {
	name: string
	pixels: (string | undefined)[][]
}

function nonogrammFromSpec (spec: NonogrammSpec): Nonogramm {
	return {
		id: -1,
		name: spec.name,
		cols: spec.pixels.map((column, index) => ({
			id: index + 1,
			rows: column.map((pixel, index) => ({
				id: index + 1,
				color: pixel,
				state: pixel ? SlabStateEnum.coloredSlab : SlabStateEnum.markedByPlayer
			}))
		}))
	}
}

// TODO: Kommentar hinzufügen
@Options({
	components: {
		BreakLine,
		Headline,
		NumberBlock,
		Slab,
		GameGrid
	}
})
export default class HowToPlay extends Vue {
	tutorial1: Nonogramm = nonogrammFromSpec({
		name: 'tutorial1',
		pixels: [['#e99134', '#e99134', '#e99134', '#e99134', '#e99134']]
	})

	tutorial2: Nonogramm = nonogrammFromSpec({
		name: 'tutorial2',
		pixels: [['#e99134', undefined, '#e99134', '#e99134', '#e99134']]
	})

	tutorial3: Nonogramm = nonogrammFromSpec({
		name: 'tutorial3',
		pixels: [['#e99134', undefined, '#e99134', undefined, '#e99134']]
	})
}
