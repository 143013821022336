import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e76ee24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings" }
const _hoisted_2 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headline = _resolveComponent("headline")!
  const _component_navigation_button = _resolveComponent("navigation-button")!
  const _component_break_line = _resolveComponent("break-line")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_headline, { title: "Einstellungen" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_navigation_button, { title: _ctx.soundSettingButtonTitle }, null, 8, ["title"]),
      _createVNode(_component_navigation_button, { title: _ctx.musicSettingButtonTitle }, null, 8, ["title"]),
      _createVNode(_component_navigation_button, { title: "Teilen" }),
      _createVNode(_component_navigation_button, { title: "Zurücksetzen" })
    ]),
    _createVNode(_component_break_line),
    _createVNode(_component_navigation_button, {
      class: "backButton",
      icon: "chevron-left",
      title: "Zurück",
      navigateTo: "/"
    })
  ]))
}