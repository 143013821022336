import LocalStorageOptions from '@/types/LocalStorageOptions'
import Tracker from '@/utils/CreataleTracker'

interface Config {
	localStorage: LocalStorageOptions
	gdpr: {
		serviceURL: string
		gameId: string
		currentPrivacyVersion: number
		Tracker?: {
			enable(): void
			disable(): void
			target(): string
			createUserId(): string
		}
		OnlineHighscore?: {
			enable(): void
			disable(): void
		}
	}
	tracker: {
		serviceURL: string
		authKey: string
	}
	shareUrl: string
	publicPath: string
}

export default {
	localStorage: {
		localStoragePrefix: 'christmas-pix-',
		keyDefinitions: {
			alreadyVisited: {
				serialize: JSON.stringify,
				deserialize: JSON.parse,
				defaultValue: false
			}
		}
	},
	gdpr: {
		serviceURL: 'https://gdpr-services.creatale.de',
		gameId: 'f17345f9-a1d2-4d91-b051-117968ec7523',
		currentPrivacyVersion: 1,
		Tracker
	},
	tracker: {
		serviceURL: 'https://games-services.creatale.de',
		authKey: '53945351-fed4-4cff-ba1d-17bf4367886c'
	},
	shareUrl: 'https://christmas-pix.creatale.de',
	publicPath: process.env.BASE_URL
} as Config
