
import { ClassComponentHooks, prop, Vue } from 'vue-class-component'

import Slab from '@/models/Slab'
import SlabStateEnum from '@/models/SlabStateEnum'

import store from '@/store'

// Define props in a class
class Props {
	puzzleSlab = prop<Slab>({
		required: true
	})
	
	colId = prop<number>({
		default: 1
	})
}

export default class SlabComponent extends Vue.with(Props) implements ClassComponentHooks {
	/**
	 * Enumeration zur Bestimmung des Feldzustandes
	 */
	slabState = SlabStateEnum

	/**
	 * bestimmt anhand des Status des Slabs die zu nutzende Farbe des Feldes
	 */
	get activeColor (): string {
		if (this.puzzleSlab.state === SlabStateEnum.coloredSlab) {
			return this.puzzleSlab.color || 'transparent'
		} else {
			return 'transparent'
		}
	}

	/**
	 * bestimmt anhand des Status des Slabs die zu nutzende Markierungsfarbe des Feldes
	 */
	get markColor (): string {
		if (this.puzzleSlab.state === SlabStateEnum.markedByPlayer) {
			return 'grey'
		} else {
			return 'red'
		}
	}
	
	get outlineStyle (): Record<string, unknown> {
		return {
			gridLineVertical: this.insertLine(this.puzzleSlab.id),
			gridLineHorizontal: this.insertLine(this.colId),
			slabOutline: true
		}
	}

	/**
	 * ändert Zustand und Inhalt des Feldes, je nachdem, ob das Feld ausgefüllt sein muss oder nicht
	 */
	changeState (): void {
		store.dispatch('nonogramms/markSlab', {
			colId: this.colId, slabId: this.puzzleSlab.id
		})
	}

	/**
	 * gibt an, ob eine dickere Gridlinie am Feldrand dargestellt werden soll,
	 * um eine bessere visuelle Einteilung des Spielfeldes zu ermöglichen
	 * @param pos Position des Feldes auf dem Spielfeld
	 *
	 * @returns boolean wenn true befindet sich das Feld an der Position einer hervorzuhebenden Gridlinie
	 */
	insertLine (pos: number): boolean {
		return (pos % 5 === 0)
	}
}

