import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cfff114"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "field"
}
const _hoisted_2 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slab_selection = _resolveComponent("slab-selection")!
  const _component_navigation_button = _resolveComponent("navigation-button")!
  const _component_block_numbers = _resolveComponent("block-numbers")!
  const _component_slab = _resolveComponent("slab")!
  const _directive_touch = _resolveDirective("touch")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    onMouseup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onStopMarking && _ctx.onStopMarking(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onOutOfBounds && _ctx.onOutOfBounds(...args)))
  }, [
    _createVNode(_component_slab_selection, { dragTracker: _ctx.dragTracker }, null, 8, ["dragTracker"]),
    (!_ctx.hideTopBlockNumbers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_navigation_button, {
            class: "backButton",
            icon: "chevron-left",
            navigateTo: "/levels"
          }),
          _createVNode(_component_block_numbers, {
            class: "alignVertical",
            blockNumbers: _ctx.colBlockNumbers,
            smallBlocks: _ctx.smallBlocks
          }, null, 8, ["blockNumbers", "smallBlocks"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_block_numbers, {
        blockNumbers: _ctx.rowBlockNumbers,
        smallBlocks: _ctx.smallBlocks
      }, null, 8, ["blockNumbers", "smallBlocks"]),
      _createElementVNode("div", {
        class: "outerBorder",
        onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAbortMarking && _ctx.onAbortMarking(...args))),
        onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onReEnterWhileMarking && _ctx.onReEnterWhileMarking(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nonogramm.cols, (col, colIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "grid",
            key: colIndex
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(col.rows, (slab, rowIndex) => {
              return (_openBlock(), _createElementBlock("div", { key: rowIndex }, [
                _withDirectives(_createVNode(_component_slab, {
                  onMousedown: ($event: any) => (_ctx.onStartMarking($event, slab.id, col.id)),
                  puzzleSlab: slab,
                  colId: col.id
                }, null, 8, ["onMousedown", "puzzleSlab", "colId"]), [
                  [_directive_touch, (event) => _ctx.onTouchPress(event, slab.id, col.id), "press"],
                  [_directive_touch, _ctx.onTouchDrag, "drag"],
                  [_directive_touch, _ctx.onRelease, "release"]
                ])
              ]))
            }), 128))
          ]))
        }), 128))
      ], 32)
    ])
  ], 32)), [
    [_directive_touch, _ctx.onMouseMovement, "rollover"]
  ])
}