import { ActionContext } from 'vuex'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

import b64Sha512 from '@/utils/sha512'

import config from '@/config'
import LocalStorageProvider from '@/utils/LocalStorageProvider'

import GdprState from '@/store/types/GdprState'
import AppState from '@/store/types/AppState'

interface UpdateDataPrivacyStatusPayload {
	privacyPolicyAccepted: boolean
	gameAnalyticsAccepted: boolean
	fullAgeAccepted: boolean
	onlineHighscoreAccepted: boolean
}

function makeSecret (): string {
	let text = ''
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

	for (let i = 0; i < 16; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length))
	}

	return text
}

const state: GdprState = {
	privacyPolicySeen: 0,
	privacyPolicyAccepted: false,
	gameAnalyticsAccepted: false,
	onlineHighscoreAccepted: false,
	fullAgeAccepted: false,
	secret: '',
	userId: '',
	trackerAvailable: false,
	trackerTarget: '',
	onlineHighscoreAvailable: false,
	initialized: false
}

function enableComponents (state: GdprState): void {
	if (
		state.trackerAvailable
		&& config.gdpr.Tracker
	) {
		if (state.gameAnalyticsAccepted) {
			config.gdpr.Tracker.enable()
		} else {
			config.gdpr.Tracker.disable()
		}
	}

	if (
		state.onlineHighscoreAvailable
		&& config.gdpr.OnlineHighscore
	) {
		if (state.onlineHighscoreAccepted) {
			config.gdpr.OnlineHighscore.enable()
		} else {
			config.gdpr.OnlineHighscore.disable()
		}
	}
}

const mutations = {
	updateDataPrivacyStatus (
		state: GdprState,
		payload: UpdateDataPrivacyStatusPayload
	): void {
		const {
			privacyPolicyAccepted,
			gameAnalyticsAccepted,
			fullAgeAccepted,
			onlineHighscoreAccepted
		} = payload

		state.privacyPolicyAccepted = privacyPolicyAccepted
		state.gameAnalyticsAccepted = gameAnalyticsAccepted
		state.fullAgeAccepted = fullAgeAccepted
		state.onlineHighscoreAccepted = onlineHighscoreAccepted
		state.privacyPolicySeen = config.gdpr.currentPrivacyVersion

		LocalStorageProvider.save({
			privacyPolicyAccepted,
			gameAnalyticsAccepted,
			fullAgeAccepted,
			onlineHighscoreAccepted,
			privacyPolicySeen: config.gdpr.currentPrivacyVersion
		})
	},
	updateSecret (state: GdprState, secret: string): void {
		state.secret = secret

		LocalStorageProvider.save({
			secret
		})
	},
	initialize (state: GdprState): void {
		state.trackerAvailable = config.gdpr.Tracker !== undefined
		state.trackerTarget = config.gdpr.Tracker ? config.gdpr.Tracker.target() : ''
		state.onlineHighscoreAvailable = config.gdpr.OnlineHighscore !== undefined
		state.privacyPolicySeen = LocalStorageProvider.privacyPolicySeen as number
		state.privacyPolicyAccepted = LocalStorageProvider.privacyPolicyAccepted as boolean
		state.gameAnalyticsAccepted = LocalStorageProvider.gameAnalyticsAccepted as boolean
		state.fullAgeAccepted = LocalStorageProvider.fullAgeAccepted as boolean
		state.onlineHighscoreAccepted = LocalStorageProvider.onlineHighscoreAccepted as boolean
		state.secret = LocalStorageProvider.secret as string
		state.userId = LocalStorageProvider.userId as string
		state.initialized = true
		enableComponents(state)
	}
}

const actions = {
	updateDataPrivacyStatus (
		{ state, commit }: ActionContext<GdprState, AppState>,
		payload: UpdateDataPrivacyStatusPayload
	): Promise<unknown> {
		const {
			privacyPolicyAccepted,
			gameAnalyticsAccepted,
			fullAgeAccepted,
			onlineHighscoreAccepted
		} = payload

		commit('updateDataPrivacyStatus', payload)

		return axios.post(config.gdpr.serviceURL + '/game/' + config.gdpr.gameId, {
			userId: state.userId,
			privacyVersion: config.gdpr.currentPrivacyVersion,
			privacyStatement: privacyPolicyAccepted,
			gameAnalytics: gameAnalyticsAccepted,
			onlineHighscore: onlineHighscoreAccepted,
			fullAge: fullAgeAccepted
		})
			.then((response) => {
				const serverSignature = response.data.serverSignature
				const secret = makeSecret()
				commit('updateSecret', secret)

				const signatureContent = serverSignature + ';' + secret
				const signature = b64Sha512(signatureContent)

				return axios.post(config.gdpr.serviceURL + '/game/' + config.gdpr.gameId + '/sign', {
					userId: state.userId,
					version: config.gdpr.currentPrivacyVersion,
					serverSignature,
					signature
				})
			})
	},
	initialize ({ state, commit }: ActionContext<GdprState, AppState>): Promise<void> {
		if (!state.initialized) {
			const { gdpr, localStorage } = config
			const { Tracker } = gdpr
			const { localStoragePrefix } = localStorage

			LocalStorageProvider.initialize({
				keyDefinitions: {
					privacyPolicySeen: {
						serialize: JSON.stringify,
						deserialize: JSON.parse,
						defaultValue: 0
					},
					privacyPolicyAccepted: {
						serialize: JSON.stringify,
						deserialize: JSON.parse,
						defaultValue: false
					},
					gameAnalyticsAccepted: {
						serialize: JSON.stringify,
						deserialize: JSON.parse,
						defaultValue: false
					},
					onlineHighscoreAccepted: {
						serialize: JSON.stringify,
						deserialize: JSON.parse,
						defaultValue: false
					},
					fullAgeAccepted: {
						serialize: JSON.stringify,
						deserialize: JSON.parse,
						defaultValue: false
					},
					secret: {
						defaultValue: ''
					},
					userId: {
						defaultValue: Tracker && typeof Tracker.createUserId === 'function' ? Tracker.createUserId() : uuid()
					}
				},
				localStoragePrefix: localStoragePrefix + '__plugin-gdpr-'
			})
			commit('initialize')
		}

		return Promise.resolve()
	}
}

const getters = {
	needsToShowGdpr (state: GdprState): boolean {
		return state.privacyPolicySeen < config.gdpr.currentPrivacyVersion || !state.privacyPolicyAccepted || !state.privacyPolicySeen
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
