import { ActionContext } from 'vuex'

import nonogramms from '@/assets/Nonogramms'

import Nonogramm from '@/models/Nonogramm'
import SlabStateEnum from '@/models/SlabStateEnum'
import Slab from '@/models/Slab'

import LocalStorageProvider from '@/utils/LocalStorageProvider'

import NonogrammInfo from '@/types/NonogrammInfo'
import SlabPosition from '@/types/SlabPosition'

import AppState from '@/store/types/AppState'
import NonogrammsState from '@/store/types/NonogrammsState'

import config from '@/config'

function markedSlabIsInvalid (slab: Slab): boolean {
	if (slab.color) {
		slab.state = SlabStateEnum.coloredSlab
	} else if (slab.state === SlabStateEnum.emptySlab) {
		slab.state = SlabStateEnum.crossedOut
		return true
	}
	return false
}

function nonogrammIsSolved (
	nonogramm: Nonogramm
): boolean {
	return nonogramm.cols.every(
		(col) => col.rows.every(
			(slab) => !slab.color || slab.state === SlabStateEnum.coloredSlab
		)
	)
}

function resetNonogramm (
	nonogramm: Nonogramm
): void {
	for (const { rows } of nonogramm.cols) {
		for (const slab of rows) {
			slab.state = SlabStateEnum.emptySlab
		}
	}
}

function fixUndefinedSlabStates (
	nonogramm: Nonogramm
): void {
	for (const { rows } of nonogramm.cols) {
		for (const slab of rows) {
			if (!slab.state) {
				slab.state = SlabStateEnum.emptySlab
			}
		}
	}
}

const state: NonogrammsState = {
	levelInfos: [],
	solutions: [],
	currentSolution: nonogramms.EMPTY_PUZZLE,
	initialized: false
}

const mutations = {
	initialize (state: NonogrammsState): void {
		const levelParameter = nonogramms.getPuzzleInfos()
		
		state.solutions = LocalStorageProvider.solutions as Nonogramm[]
		
		const levelInfos: NonogrammInfo[] = levelParameter.map((level) => {
			const isInLocalStorage = state.solutions.find(puzzle => puzzle.id === level.id) as Nonogramm
			
			return {
				...level,
				hasBeenSolved: isInLocalStorage && isInLocalStorage.hasBeenSolved
			}
		})
		
		state.levelInfos = levelInfos
		state.initialized = true
	},
	/**
	 * lädt vorhandene Puzzlestände aus dem LocalStorage
	 *
	 * @returns letzten Lösungsstand eines Rätsels oder leeres Rätselspielfeld,
	 * wenn Rätsel beim letzten Spielen gelöst wurde
	 */
	updateCurrentSolution (state: NonogrammsState, puzzleId: number): void {
		const existingSolution = state.solutions.find((solution: Nonogramm) => solution.id === puzzleId)
		const currentSolution = existingSolution || nonogramms.getPuzzleById(puzzleId)
		
		if (nonogrammIsSolved(currentSolution)) {
			resetNonogramm(currentSolution)
		}
		
		fixUndefinedSlabStates(currentSolution)
		
		state.currentSolution = currentSolution
	},
	storeProgress (state: NonogrammsState, solved: boolean): void {
		const existingSolution = state.solutions.find((solution: Nonogramm) => solution.id === state.currentSolution.id)
		if (!existingSolution) {
			state.solutions.push(state.currentSolution)
		}
		
		if (solved) {
			state.currentSolution.hasBeenSolved = true
			const levelInfo = state.levelInfos.find((levelInfo: NonogrammInfo) => levelInfo.id === state.currentSolution.id)
			
			if (levelInfo) {
				levelInfo.hasBeenSolved = true
			}
		}
		
		LocalStorageProvider.save({
			solutions: state.solutions
		})
	},
	resetPuzzle (state: NonogrammsState): void {
		resetNonogramm(state.currentSolution)
	},
	markSlabs (state: NonogrammsState, markedSlabs: SlabPosition[]): void {
		let continueLoop = true
		
		for (let i = 0; i < markedSlabs.length && continueLoop; i++) {
			const { colId, slabId } = markedSlabs[i]
			
			const slab = state.currentSolution.cols[colId - 1]?.rows[slabId - 1]
			
			continueLoop = !!slab && !markedSlabIsInvalid(slab)
		}
	},
	fillSolvedCols (state: NonogrammsState): void {
		for (const col of state.currentSolution.cols) {
			const colSolved = col.rows.every((slab) => !slab.color || slab.state === SlabStateEnum.coloredSlab)
			
			if (colSolved) {
				for (const slab of col.rows) {
					if (slab.state === SlabStateEnum.emptySlab) {
						slab.state = SlabStateEnum.markedByPlayer
					}
				}
			}
		}
	},
	fillSolvedRows (state: NonogrammsState): void {
		for (let i = 0; i < state.currentSolution.cols[0].rows.length; i++) {
			const rowSolved = state.currentSolution.cols.every((col) => {
				const slab = col.rows[i]
				
				return slab && (!slab.color || slab.state === SlabStateEnum.coloredSlab)
			})
			
			if (rowSolved) {
				for (const col of state.currentSolution.cols) {
					const slab = col.rows[i]
					
					if (slab && slab.state === SlabStateEnum.emptySlab) {
						slab.state = SlabStateEnum.markedByPlayer
					}
				}
			}
		}
	}
}

const actions = {
	initialize ({ state, commit }: ActionContext<NonogrammsState, AppState>): Promise<void> {
		if (!state.initialized) {
			const { localStorage } = config
			const { localStoragePrefix } = localStorage
			
			LocalStorageProvider.initialize({
				keyDefinitions: {
					solutions: {
						serialize: JSON.stringify,
						deserialize: JSON.parse,
						defaultValue: []
					}
				},
				localStoragePrefix
			})

			commit('initialize')
		}
		
		return Promise.resolve()
	},
	updateCurrentSolution ({ commit }: ActionContext<NonogrammsState, AppState>, puzzleId: number): Promise<void> {
		commit('updateCurrentSolution', puzzleId)
		
		return Promise.resolve()
	},
	storeProgress ({ commit, getters }: ActionContext<NonogrammsState, AppState>): void {
		commit('storeProgress', getters.isSolved)
	},
	resetPuzzle ({ commit }: ActionContext<NonogrammsState, AppState>): void {
		commit('resetPuzzle')
	},
	markSlabs ({ commit, dispatch }: ActionContext<NonogrammsState, AppState>, markedSlabs: SlabPosition[]): void {
		commit('markSlabs', markedSlabs)
		commit('fillSolvedCols')
		commit('fillSolvedRows')
		
		dispatch('storeProgress')
	},
	markSlab ({ dispatch }: ActionContext<NonogrammsState, AppState>, position: SlabPosition): void {
		dispatch('markSlabs', [position])
	}
}

const getters = {
	isSolved (state: NonogrammsState): boolean {
		return nonogrammIsSolved(state.currentSolution)
	},
	germanTitle (state: NonogrammsState): string {
		return nonogramms.getGermanName(state.currentSolution.name)
	},
	isLastLevel (state: NonogrammsState): boolean {
		return nonogramms.isLastPuzzle(state.currentSolution.id)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
