import {
	createRouter,
	createWebHashHistory,
	RouteRecordRaw,
	RouteLocationNormalized,
	RouteLocationRaw
} from 'vue-router'
import GameGrid from '@/views/GameGrid.vue'
import MainMenu from '@/views/MainMenu.vue'
import HowToPlay from '@/views/HowToPlay.vue'
import Settings from '@/views/Settings.vue'
import Levels from '@/views/Levels.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import store from '@/store'
import LocalStorageProvider from '@/utils/LocalStorageProvider'
import Tracker from '@/utils/CreataleTracker'
import config from '@/config'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'main-menu',
		component: MainMenu
	},
	{
		path: '/play/:levelId',
		name: 'game-screen',
		component: GameGrid
	},
	{
		path: '/how-to-play',
		name: 'how-to-play',
		component: HowToPlay
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: PrivacyPolicy
	},
	{
		path: '/levels',
		name: 'levels',
		component: Levels
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// TODO: this should be exported by vue-router, but isn't
declare type NavigationGuardReturn = RouteLocationRaw | boolean

router.beforeEach((to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
	return store.dispatch('gdpr/initialize')
		.then(() => store.dispatch('nonogramms/initialize'))
		.then(() => {
			if (!Tracker.initialized) {
				Tracker.initialize({
					server: config.tracker.serviceURL,
					authKey: config.tracker.authKey,
					gameId: config.gdpr.gameId,
					localStoragePrefix: config.localStorage.localStoragePrefix,
					userId: store.state.gdpr.userId
				})
				
				if (store.state.gdpr.gameAnalyticsAccepted) {
					Tracker.enable()
				}
			}

			Tracker.addEvent({
				type: 'UI_TRANSITION',
				subType: to.path
			})

			if (store.getters['gdpr/needsToShowGdpr']) {
				// TODO: Why is casting even necessary to make Typescript happy?
				if (to.path !== '/privacy') {
					return Promise.resolve('/privacy' as NavigationGuardReturn)
				} else {
					return Promise.resolve(true as NavigationGuardReturn)
				}
			} else if (!LocalStorageProvider.alreadyVisited) {
				if (to.path !== '/how-to-play') {
					return Promise.resolve('/how-to-play' as NavigationGuardReturn)
				} else {
					return Promise.resolve(true as NavigationGuardReturn)
				}
			} else if (to.path.startsWith('/play')) {
				return store.dispatch('nonogramms/updateCurrentSolution', parseInt(to.params.levelId as string, 10))
					.then(() => Promise.resolve(true as NavigationGuardReturn))
			} else {
				return Promise.resolve(true as NavigationGuardReturn)
			}
		})
})

export default router
