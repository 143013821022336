import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bf427ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "controls" }
const _hoisted_3 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_puzzle_solved = _resolveComponent("puzzle-solved")!
  const _component_modal = _resolveComponent("modal")!
  const _component_navigation_button = _resolveComponent("navigation-button")!
  const _component_HowToPlay = _resolveComponent("HowToPlay")!
  const _component_game_grid = _resolveComponent("game-grid", true)!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_break_line = _resolveComponent("break-line")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal, { "is-visible": _ctx.isSolved }, {
      default: _withCtx(() => [
        _createVNode(_component_puzzle_solved, {
          title: _ctx.germanTitle,
          levelId: _ctx.puzzle.id,
          lastLevel: _ctx.isLastLevel,
          imageSrc: _ctx.puzzleImagePath,
          onResetPuzzle: _ctx.onResetPuzzle
        }, null, 8, ["title", "levelId", "lastLevel", "imageSrc", "onResetPuzzle"])
      ]),
      _: 1
    }, 8, ["is-visible"]),
    _createVNode(_component_modal, {
      "is-visible": _ctx.showHowTo,
      "no-transition": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HowToPlay, null, {
          default: _withCtx(() => [
            _createVNode(_component_navigation_button, {
              icon: "chevron-left",
              title: "Zurück",
              onClick: _ctx.closeHowTo
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-visible"]),
    _createVNode(_component_game_grid, {
      nonogramm: _ctx.puzzle,
      class: "gameGrid"
    }, null, 8, ["nonogramm"]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-circle-info",
          size: "1x",
          onClick: _ctx.onShowHowTo
        }, null, 8, ["onClick"])
      ]),
      _createVNode(_component_break_line),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_navigation_button, {
            icon: "fa-solid fa-arrow-rotate-left",
            title: "Restart",
            onClick: _ctx.onResetPuzzle
          }, null, 8, ["onClick"])
        ])
      ])
    ])
  ], 64))
}