
import { Options, Vue } from 'vue-class-component'

import HowToPlayComponent from '@/components/HowToPlay.vue'
import NavigationButton from '@/components/NavigationButton.vue'

import LocalStorageProvider from '@/utils/LocalStorageProvider'

@Options({
	components: {
		HowToPlay: HowToPlayComponent,
		NavigationButton
	}
})

export default class HowToPlay extends Vue {
	firstTimeTutorial = false

	mounted (): void {
		if (!LocalStorageProvider.alreadyVisited) {
			this.firstTimeTutorial = true
		}
	}

	play (): void {
		if (!LocalStorageProvider.alreadyVisited) {
			LocalStorageProvider.save({
				alreadyVisited: true
			})
		}
		this.$router.push('/play/1')
	}
}
