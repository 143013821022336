
import { Options, Vue } from 'vue-class-component'

@Options({
	components: {},
	props: {
		title: String
	}
})
export default class Headline extends Vue {
}
