
import { Options, prop, Vue } from 'vue-class-component'

import SlabSelection from '@/components/SlabSelection.vue'
import SlabComponent from '@/components/Slab.vue'
import BlockNumbers from '@/components/BlockNumbers.vue'
import NavigationButton from '@/components/NavigationButton.vue'

import getPuzzleInfo from '@/utils/getPuzzleInfo'

import Slab from '@/models/Slab'
import Nonogramm from '@/models/Nonogramm'
import DragTracker from '@/models/DragTracker'
import TrackingDragTracker from '@/models/TrackingDragTracker'

import NonogrammInfo from '@/types/NonogrammInfo'

import store from '@/store'

function getRowNumbers (row: Slab[]): number[] {
	const numberArray: number[] = []
	let count = 0
	
	for (const slab of row) {
		if (slab.color) {
			count++
		} else if (count !== 0) {
			numberArray.push(count)
			count = 0
		}
	}
	
	if (count !== 0) {
		numberArray.push(count)
	}
	
	return numberArray
}

class GameGridProps {
	nonogramm = prop<Nonogramm>({
		required: true
	})
	
	hideTopBlockNumbers = false
}

@Options<GameGrid>({
	components: {
		SlabSelection,
		Slab: SlabComponent,
		NavigationButton,
		BlockNumbers
	}
})
export default class GameGrid extends Vue.with(GameGridProps) {
	/**
	 * trackt die benötigten Informationen, wenn mehrere Felder markiert werden
	 */
	dragTracker: DragTracker = new TrackingDragTracker()

	/**
	 * Zahlenblöcke der Reihen
	 */
	get rowBlockNumbers (): number[][] {
		const blockArray: number[][] = []
		
		for (const col of this.nonogramm.cols) {
			const block = getRowNumbers(col.rows)
			
			if (block) {
				blockArray.push(block)
			}
		}
		
		return blockArray
	}

	/**
	 * Zahlenblöcke der Spalten
	 */
	get colBlockNumbers (): number[][] {
		const colArray: Slab[][] = []
		let slabArray: Slab[] = []
		const blockArray: number[][] = []

		for (let i = 0; i < this.nonogramm.cols.length; i++) {
			for (const col of this.nonogramm.cols) {
				const slab = col.rows[i]
				
				if (slab) {
					slabArray.push(slab)
				}
			}
			
			colArray.push(slabArray)
			slabArray = []
		}

		for (const col of colArray) {
			const block = getRowNumbers(col)
			
			if (block) {
				blockArray.push(block)
			}
		}
		
		return blockArray
	}
	
	/**
	 * gibt an, ob die Numberblock Elemente kleiner gerendert werden müssen
	 */
	get smallBlocks (): boolean {
		return (this.nonogramm.cols.length > 15)
	}
	
	get nonogrammInfo (): NonogrammInfo {
		return getPuzzleInfo(this.nonogramm)
	}

	/**
	 * Eventhandler im Desktop-Modus
	 * setzt den Flag, dass die aktive Auswahl nicht angewendet wird, da sich die Maus außerhalb des Spielfeldes befindet
	 */
	onAbortMarking (): void {
		this.dragTracker.abortMarking()
	}

	/**
	 * Eventhandler im Desktop-Modus
	 * entfernt den Flag, dass die aktive Auswahl nicht angewendet wird, da die Maus zurück auf das Spielfeld bewegt wurde,
	 * während der Auswahlmodus noch aktiv war
	 */
	onReEnterWhileMarking (): void {
		this.dragTracker.resumeMarking()
	}

	/**
	 * EventHandler im mobilen Modus
	 * die Anfangswerte (Position, Feld- und Spalten-ID, Feldgröße) für den Auswahlmodus werden erfasst und es wird
	 * ein Flag gesetzt, dass in den Auswahlmodus gewechselt wurde
	 */
	onTouchPress (
		event: TouchEvent | MouseEvent,
		slabId: number,
		colId: number
	): void {
		if (event.type === 'mousedown') {
			event.preventDefault()
			event.stopPropagation()
			return
		}
		
		const slab = (event.target as Element).getBoundingClientRect()
		
		this.dragTracker.startMarking(slabId, colId, slab)
	}

	/**
	 * Eventhandler im mobilen Modus, wenn der Spieler über das Spielfeld streicht
	 * Ermittelt die Distanzen zwischen aktueller Position und Anfangsposition
	 */
	onTouchDrag (event: TouchEvent): void {
		if (event.type !== 'mousemove') {
			const touch = event.changedTouches[0]
			this.dragTracker.resolveMarkingMovement(
				touch.clientX,
				touch.clientY,
				this.nonogrammInfo
			)
		} else {
			event.preventDefault()
			event.stopPropagation()
		}
	}

	/**
	 * Eventhandler im mobilen Modus
	 * stößt die Auswertung der erfassten Daten der Auswahl an, wemm der Spieler den Bildschirm nicht mehr berührt
	 */
	onRelease (event: TouchEvent | MouseEvent): void {
		if (event.type !== 'mouseup') {
			this.dragTracker.stopMarking()
			
			if (this.dragTracker.hasMoved) {
				this.applyMarking()
			}
		}
	}

	/**
	 * wendet die getroffene Auswahl auf das aktuelle Puzzle an und setzt die Auswahlwerte auf die Default-Werte zurück
	 */
	applyMarking (): void {
		if (this.dragTracker.isTracking) {
			if (this.dragTracker instanceof TrackingDragTracker) {
				this.dragTracker.trackMovement()
			}
			
			store.dispatch(
				'nonogramms/markSlabs',
				this.dragTracker.getMarkedSlabs()
			)
		}
		
		this.dragTracker.reset()
	}

	/**
	 * EventHandler im Desktop-Modus
	 * die Anfangswerte (Position, Feld- und Spalten-ID, Feldgröße) für den Auswahlmodus werden erfasst und es wird
	 * ein Flag gesetzt, dass in den Auswahlmodus gewechselt wurde
	 */
	onStartMarking (event: MouseEvent, slabId: number, colId: number): void {
		event.stopPropagation()
		event.preventDefault()
		
		this.dragTracker.startMarking(
			slabId,
			colId,
			(event.target as Element).getBoundingClientRect()
		)
	}

	/**
	 * Eventhandler im Desktop-Modus, wenn Spieler die gedrückte Maustaste loslässt
	 * stößt die Auswertung der erfassten Daten der Auswahl an
	 */
	onStopMarking (): void {
		this.dragTracker.stopMarking()
		
		if (this.dragTracker.hasMoved) {
			this.applyMarking()
		}
	}

	onOutOfBounds (): void {
		this.dragTracker.cancelTracking()
		this.applyMarking()
	}

	/**
	 * Eventhandler im Desktop-Modus, wenn der Spieler den Cursor bei gedrückter linker Maustaste über das Spielfeld bewegt
	 * Ermittelt die Distanzen zwischen aktueller Position und Anfangsposition
	 */
	onMouseMovement (event: MouseEvent): void {
		if (event.type === 'mousemove') {
			event.preventDefault()
			event.stopPropagation()
			
			if (
				this.dragTracker.isMarking
				&& event.buttons === 1
			) {
				this.dragTracker.resolveMarkingMovement(
					event.clientX,
					event.clientY,
					this.nonogrammInfo
				)
			}
		}
	}
}
