import { createStore } from 'vuex'
import gdpr from './modules/gdpr'
import nonogramms from './modules/nonogramms'

export default createStore({
	modules: {
		gdpr,
		nonogramms
	}
})
