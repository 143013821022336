import Nonogramm from '@/models/Nonogramm'

import NonogrammInfo from '@/types/NonogrammInfo'

export default function getPuzzleInfo (
	puzzle: Nonogramm
): NonogrammInfo {
	const { id, name, cols } = puzzle
	
	return {
		id,
		name,
		cols: cols.length,
		rows: cols[0]?.rows.length ?? 0
	}
}
