import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f79ebe4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "numbers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_block = _resolveComponent("number-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockNumbers, (col, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "blocks",
        key: index
      }, [
        _createVNode(_component_number_block, {
          blockNumbers: col,
          small: _ctx.smallBlocks
        }, null, 8, ["blockNumbers", "small"])
      ]))
    }), 128))
  ]))
}